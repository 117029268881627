// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const UserAccountType = {
  "ORGANIZATIONADMIN": "ORGANIZATIONADMIN",
  "ORGANIZATIONUSER": "ORGANIZATIONUSER",
  "INDIVIDUAL": "INDIVIDUAL"
};

const JobApplicationStatus = {
  "SUBMITTED": "SUBMITTED",
  "VIEWED": "VIEWED",
  "ACCEPTED": "ACCEPTED",
  "IN_PROGRESS": "IN_PROGRESS",
  "REMOVED": "REMOVED",
  "COMPLETE": "COMPLETE"
};

const JobPostStatus = {
  "DRAFT": "DRAFT",
  "ACTIVE": "ACTIVE",
  "CLOSED": "CLOSED",
  "ARCHIVED": "ARCHIVED"
};

const JobCompensationType = {
  "HOURLY": "HOURLY",
  "SALARIED": "SALARIED"
};

const EmploymentStatusType = {
  "FULLTIME": "FULLTIME",
  "PARTTIME": "PARTTIME",
  "SEASONAL": "SEASONAL",
  "CONTRACT": "CONTRACT"
};

const JobLocationType = {
  "REMOTE": "REMOTE",
  "OFFICE": "OFFICE",
  "HYBRID": "HYBRID"
};

const VideoLocationType = {
  "EXTERNAL": "EXTERNAL",
  "INTERNAL": "INTERNAL",
  "UPLOAD": "UPLOAD"
};

const PlatformInviteStatus = {
  "SENT": "SENT",
  "INPROGRESS": "INPROGRESS",
  "COMPLETE": "COMPLETE"
};

const { CandidatesKanbanColumn, JobApplicationAssignee, JobApplicationComment, JobApplicationRating, JobApplications, BoomerangProfile, BoomerangProfileElements, EducationInstitutions, JobPost, AccountSettings, PlatformInvite, UserProfile, Organization, CertificationHistory, VideoIntroduction, EducationHistory, WorkExperience, ApplicantProfile, ContentCreatorProfile, VideoContent, Message, Conversation, UserConversation, ChatUser, ChatContact, CertificationHistoryApplicantProfile, VideoIntroductionApplicantProfile, EducationHistoryApplicantProfile, WorkExperienceApplicantProfile, Location, ChatParticipant, ChatAttachment } = initSchema(schema);

export {
  CandidatesKanbanColumn,
  JobApplicationAssignee,
  JobApplicationComment,
  JobApplicationRating,
  JobApplications,
  BoomerangProfile,
  BoomerangProfileElements,
  EducationInstitutions,
  JobPost,
  AccountSettings,
  PlatformInvite,
  UserProfile,
  Organization,
  CertificationHistory,
  VideoIntroduction,
  EducationHistory,
  WorkExperience,
  ApplicantProfile,
  ContentCreatorProfile,
  VideoContent,
  Message,
  Conversation,
  UserConversation,
  ChatUser,
  ChatContact,
  CertificationHistoryApplicantProfile,
  VideoIntroductionApplicantProfile,
  EducationHistoryApplicantProfile,
  WorkExperienceApplicantProfile,
  UserAccountType,
  JobApplicationStatus,
  JobPostStatus,
  JobCompensationType,
  EmploymentStatusType,
  JobLocationType,
  VideoLocationType,
  PlatformInviteStatus,
  Location,
  ChatParticipant,
  ChatAttachment
};