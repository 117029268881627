import PropTypes from 'prop-types';
// @mui
import { List, Stack } from '@mui/material';
// hooks
import { useAuthContext } from '../../../auth/useAuthContext';
// locales
import { useLocales } from '../../../locales';
//
import { StyledSubheader } from './styles';
import NavList from './NavList';

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  sx: PropTypes.object,
  data: PropTypes.array,
};

export default function NavSectionVertical({ data, sx, ...other }) {
  const { user } = useAuthContext()
  const currentUserRoles = user.roles
  const { translate } = useLocales();

  return (
    <Stack sx={sx} {...other}>
      {data.map((group) => {
        const key = group.subheader || group.items[0].title;
        if (group.accessRoles.length === 0) {
          return null
        }

        const showSection = group.accessRoles.some((role) => currentUserRoles.includes(role))

        if (!showSection) {
          return null
        }

        return (
          <List key={key} disablePadding sx={{ px: 2 }}>
            {group.subheader && (
              <StyledSubheader disableSticky>{translate(group.subheader)}</StyledSubheader>
            )}

            {group.items.map((list) => {
              const showSubSection = list.accessRoles.some((role) => currentUserRoles.includes(role))
              if (!showSubSection) {
                return null
              }
              return <NavList
                key={list.title + list.path}
                data={list}
                depth={1}
                hasChild={!!list.children}
              />
            })}
          </List>
        );
      })}
    </Stack>
  );
}
