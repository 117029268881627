import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import MainLayout from '../layouts/main';
// import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  ApplicantRegistrationPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  ForceChangePasswordPage,
  // Dashboard: General
  GeneralFilePage,
  GeneralBankingPage,
  GeneralBookingPage,
  GeneralEcommercePage,
  GeneralAnalyticsPage,
  // SUPPLIER
  MyMenuPage,
  // Dashboard: User
  // UserListPage,
  // UserEditPage,
  UserCardsPage,
  // UserCreatePage,
  UserProfilePage,
  UserAccountPage,
  // Dashboard: Ecommerce
  EcommerceShopPage,
  EcommerceCheckoutPage,
  EcommerceProductListPage,
  EcommerceProductEditPage,
  EcommerceProductCreatePage,
  EcommerceProductDetailsPage,
  // Dashboard: Invoice
  InvoiceListPage,
  InvoiceDetailsPage,
  InvoiceCreatePage,
  InvoiceEditPage,
  // Dashboard: Blog
  BlogPostsPage,
  BlogPostPage,
  BlogNewPostPage,
  // Dashboard: FileManager
  FileManagerPage,
  // Dashboard: App
  ChatPage,
  MailPage,
  CalendarPage,
  KanbanPage,
  //
  BlankPage,
  PermissionDeniedPage,
  //
  Page500,
  Page403,
  Page404,
  HomePageJobSeeker,
  HomePageBusinesses,
  FaqsPage,
  AboutPage,
  Contact,
  PricingPage,
  PricingPageEmployer,
  PricingPageJobSeeker,
  // PaymentPage,
  ComingSoonPage,
  MaintenancePage,
  // DemoEditorPage,

  // General Dashboard Pages
  GeneralAppHomePage,

  // Legal Compliance
  PrivacyPolicyPage,
  TermsConditionsPage,

  // organization pages
  ClientOrganizationListPage,
  ClientOrganizationCreatePage,
  ClientOrganizationUpdatePage,

  // user administration
  UserCreateAdminPage,
  UserEditAdminPage,
  UserListAdminPage,

  // oragnization user administration
  OrganizationUserListPage,
  OrganizationUserCreatePage,
  OrganizationUserEditPage,

  // networking
  InviteProfessionalsPage,

  // content creator
  ContentCreationPage,

  // content seeker
  ResourceVideoContentPage,
  ContentCreatorsListPage,
  ContentCreatorProfilePage,

  // my organization
  MyOrganizationUpdatePage,
  PricingSubscriptionTable,
  DemoFormValidationPage,
  MUIPickersPage,
  WaitListPage,

  // admin data administration
  BusinessListPage,
  BusinessCreatePage,
  BusinessUpdatePage,
  BusinessViewPage,

  // super admin order administration
  AdminOrdersListViewPage,

  // consumer ordering pages
  ShopListingsPage,
  ShopMenuPage,

  // cart pages
  OrderCheckoutPage,
  MyUserOrdersPage,

  // weeks management
  WeeksListPage,
  // shop pages
  ShopOwnerOrdersPage,

  // order information
  OrderReceiptPage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register/applicant',
          element: (
            <GuestGuard>
              <ApplicantRegistrationPage />
            </GuestGuard>
          ),
        },
        // { path: 'login-unprotected', element: <LoginPage /> },
        // { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
            { path: 'create-new-password', element: <ForceChangePasswordPage /> },
          ],
        },
      ],
    },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'home', element: <GeneralAppHomePage /> },
        { path: 'my-menu', element: <MyMenuPage /> },
        // { path: 'shop-listings', element: <ShopListingsPage /> },
        {
          path: 'shops',
          children: [
            { element: <Navigate to="/dashboard/shops/list" replace />, index: true },
            { path: 'list', element: <ShopListingsPage /> },
            { path: ':id/menu', element: <ShopMenuPage /> },
          ],
        },
        {
          path: 'cart',
          children: [
            { element: <Navigate to="/dashboard/cart/checkout" replace />, index: true },
            { path: 'checkout', element: <OrderCheckoutPage /> },
          ],
        },
        { path: 'my-business-orders', element: <ShopOwnerOrdersPage /> },
        { path: 'my-user-orders', element: <MyUserOrdersPage /> },
        { path: 'order/:orderId/receipt', element: <OrderReceiptPage /> },
        { path: 'ecommerce', element: <GeneralEcommercePage /> },
        { path: 'analytics', element: <GeneralAnalyticsPage /> },
        { path: 'banking', element: <GeneralBankingPage /> },
        { path: 'booking', element: <GeneralBookingPage /> },
        { path: 'file', element: <GeneralFilePage /> },
        {
          path: 'business-management',
          children: [
            { element: <Navigate to="/dashboard/business-management/list" replace />, index: true },
            { path: 'list', element: <BusinessListPage /> },
            { path: 'new', element: <BusinessCreatePage /> },
            { path: ':id/edit', element: <BusinessUpdatePage /> },
            { path: ':id/view', element: <BusinessViewPage /> },
          ],
        },
        { path: 'admin-order-management', element: <AdminOrdersListViewPage /> },
        {
          path: 'week-management',
          children: [
            { element: <Navigate to="/dashboard/week-management/list" replace />, index: true },
            { path: 'list', element: <WeeksListPage /> },
          ],
        },
        {
          path: 'account',
          children: [
            {
              element: <Navigate to="/dashboard/account/organization/update" replace />,
              index: true,
            },
            { path: 'organization/update', element: <MyOrganizationUpdatePage /> },
            { path: 'organization/subscription', element: <PricingSubscriptionTable /> },
            { path: 'organization/settings', element: <UserAccountPage /> },
            { path: 'organization/users', element: <OrganizationUserListPage /> },
            { path: 'organization/users/create', element: <OrganizationUserCreatePage /> },
            { path: 'organization/users/:id/edit', element: <OrganizationUserEditPage /> },
          ],
        },
        {
          path: 'organization',
          children: [
            { element: <Navigate to="/dashboard/organization/list" replace />, index: true },
            { path: 'list', element: <ClientOrganizationListPage /> },
            { path: 'new', element: <ClientOrganizationCreatePage /> },
            { path: ':id/edit', element: <ClientOrganizationUpdatePage /> },
          ],
        },
        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <EcommerceShopPage /> },
            { path: 'product/:name', element: <EcommerceProductDetailsPage /> },
            { path: 'list', element: <EcommerceProductListPage /> },
            { path: 'product/new', element: <EcommerceProductCreatePage /> },
            { path: 'product/:name/edit', element: <EcommerceProductEditPage /> },
            { path: 'checkout', element: <EcommerceCheckoutPage /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfilePage /> },
            { path: 'cards', element: <UserCardsPage /> },
            { path: 'list', element: <UserListAdminPage /> },
            { path: 'new', element: <UserCreateAdminPage /> },
            { path: ':id/edit', element: <UserEditAdminPage /> },
            { path: 'account', element: <UserAccountPage /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceListPage /> },
            { path: ':id', element: <InvoiceDetailsPage /> },
            { path: ':id/edit', element: <InvoiceEditPage /> },
            { path: 'new', element: <InvoiceCreatePage /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPostsPage /> },
            { path: 'post/:title', element: <BlogPostPage /> },
            { path: 'new', element: <BlogNewPostPage /> },
          ],
        },
        { path: 'files-manager', element: <FileManagerPage /> },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <MailPage /> },
            { path: 'label/:customLabel/:mailId', element: <MailPage /> },
            { path: ':systemLabel', element: <MailPage /> },
            { path: ':systemLabel/:mailId', element: <MailPage /> },
          ],
        },
        {
          path: 'networking',
          children: [
            { element: <Navigate to="/dashboard/networking/home" replace />, index: true },
            { path: 'home', element: <InviteProfessionalsPage /> },
            { path: 'invite', element: <InviteProfessionalsPage /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <ChatPage />, index: true },
            { path: 'new', element: <ChatPage /> },
            { path: 'new/:candidateId', element: <ChatPage /> },
            { path: ':conversationKey', element: <ChatPage /> },
          ],
        },
        { path: 'calendar', element: <CalendarPage /> },
        { path: 'kanban', element: <KanbanPage /> },
        { path: 'permission-denied', element: <PermissionDeniedPage /> },
        { path: 'blank', element: <BlankPage /> },
        { path: 'content-creator-suite', element: <ContentCreationPage /> },
        {
          path: 'resources',
          children: [
            {
              element: <Navigate to="/dashboard/resources/content-creators" replace />,
              index: true,
            },
            { path: 'content-creators', element: <ContentCreatorsListPage /> },
            { path: 'video-content', element: <ResourceVideoContentPage /> },
            { path: 'content-creator-profile/:profileId', element: <ContentCreatorProfilePage /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      element: <MainLayout />,
      children: [
        { element: <HomePageJobSeeker />, index: true },
        { path: 'job-seeker', element: <HomePageJobSeeker /> },
        { path: 'businesses', element: <HomePageBusinesses /> },
        { path: 'about-us', element: <AboutPage /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'join-wait-list', element: <WaitListPage /> },
        { path: 'faqs', element: <FaqsPage /> },
        { path: 'pricing', element: <PricingPage /> },
        { path: 'jobseeker/pricing', element: <PricingPageJobSeeker /> },
        { path: 'employer/pricing', element: <PricingPageEmployer /> },
        { path: 'privacy-policy', element: <PrivacyPolicyPage /> },
        { path: 'terms-and-conditions', element: <TermsConditionsPage /> },
        { path: 'form-validation', element: <DemoFormValidationPage /> },
        { path: 'pickers', element: <MUIPickersPage /> },
        // { path: 'editor', element: <DemoEditorPage /> },
        // { path: 'payment', element: <PaymentPage /> },
        // Demo Components
        // {
        //   path: 'components',
        //   children: [
        //     { element: <ComponentsOverviewPage />, index: true },
        //     {
        //       path: 'foundation',
        //       children: [
        //         { element: <Navigate to="/components/foundation/colors" replace />, index: true },
        //         { path: 'colors', element: <FoundationColorsPage /> },
        //         { path: 'typography', element: <FoundationTypographyPage /> },
        //         { path: 'shadows', element: <FoundationShadowsPage /> },
        //         { path: 'grid', element: <FoundationGridPage /> },
        //         { path: 'icons', element: <FoundationIconsPage /> },
        //       ],
        //     },
        //     {
        //       path: 'mui',
        //       children: [
        //         { element: <Navigate to="/components/mui/accordion" replace />, index: true },
        //         { path: 'accordion', element: <MUIAccordionPage /> },
        //         { path: 'alert', element: <MUIAlertPage /> },
        //         { path: 'autocomplete', element: <MUIAutocompletePage /> },
        //         { path: 'avatar', element: <MUIAvatarPage /> },
        //         { path: 'badge', element: <MUIBadgePage /> },
        //         { path: 'breadcrumbs', element: <MUIBreadcrumbsPage /> },
        //         { path: 'buttons', element: <MUIButtonsPage /> },
        //         { path: 'checkbox', element: <MUICheckboxPage /> },
        //         { path: 'chip', element: <MUIChipPage /> },
        //         { path: 'data-grid', element: <MUIDataGridPage /> },
        //         { path: 'dialog', element: <MUIDialogPage /> },
        //         { path: 'list', element: <MUIListPage /> },
        //         { path: 'menu', element: <MUIMenuPage /> },
        //         { path: 'pagination', element: <MUIPaginationPage /> },
        //         { path: 'pickers', element: <MUIPickersPage /> },
        //         { path: 'popover', element: <MUIPopoverPage /> },
        //         { path: 'progress', element: <MUIProgressPage /> },
        //         { path: 'radio-button', element: <MUIRadioButtonsPage /> },
        //         { path: 'rating', element: <MUIRatingPage /> },
        //         { path: 'slider', element: <MUISliderPage /> },
        //         { path: 'stepper', element: <MUIStepperPage /> },
        //         { path: 'switch', element: <MUISwitchPage /> },
        //         { path: 'table', element: <MUITablePage /> },
        //         { path: 'tabs', element: <MUITabsPage /> },
        //         { path: 'textfield', element: <MUITextFieldPage /> },
        //         { path: 'timeline', element: <MUITimelinePage /> },
        //         { path: 'tooltip', element: <MUITooltipPage /> },
        //         { path: 'transfer-list', element: <MUITransferListPage /> },
        //         { path: 'tree-view', element: <MUITreesViewPage /> },
        //       ],
        //     },
        //     {
        //       path: 'extra',
        //       children: [
        //         { element: <Navigate to="/components/extra/animate" replace />, index: true },
        //         { path: 'animate', element: <DemoAnimatePage /> },
        //         { path: 'carousel', element: <DemoCarouselsPage /> },
        //         { path: 'chart', element: <DemoChartsPage /> },
        //         { path: 'copy-to-clipboard', element: <DemoCopyToClipboardPage /> },
        //         { path: 'editor', element: <DemoEditorPage /> },
        //         { path: 'form-validation', element: <DemoFormValidationPage /> },
        //         { path: 'image', element: <DemoImagePage /> },
        //         { path: 'label', element: <DemoLabelPage /> },
        //         { path: 'lightbox', element: <DemoLightboxPage /> },
        //         { path: 'map', element: <DemoMapPage /> },
        //         { path: 'mega-menu', element: <DemoMegaMenuPage /> },
        //         { path: 'multi-language', element: <DemoMultiLanguagePage /> },
        //         { path: 'navigation-bar', element: <DemoNavigationBarPage /> },
        //         { path: 'organization-chart', element: <DemoOrganizationalChartPage /> },
        //         { path: 'scroll', element: <DemoScrollbarPage /> },
        //         { path: 'snackbar', element: <DemoSnackbarPage /> },
        //         { path: 'text-max-line', element: <DemoTextMaxLinePage /> },
        //         { path: 'upload', element: <DemoUploadPage /> },
        //         { path: 'markdown', element: <DemoMarkdownPage /> },
        //       ],
        //     },
        //   ],
        // },
      ],
    },
    // {
    //   element: <SimpleLayout />,
    //   children: [
    //     { path: 'pricing', element: <PricingPage /> },
    //     { path: 'payment', element: <PaymentPage /> },
    //   ],
    // },
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
