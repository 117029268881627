import { Amplify, DataStore } from 'aws-amplify';
import keyBy from 'lodash/keyBy';
import { createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/omit';
// model
import { CandidatesKanbanColumn, JobApplications, JobPost, UserProfile } from '../../models';
// utils
import axios from '../../utils/axios';
// config amplify
import awsconfig from '../../aws-exports';

Amplify.configure(awsconfig);

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  board: {
    job: null,
    cards: {},
    columns: {},
    columnOrder: [],
  },
  contacts: [],
  hiringManager: null,
  recruiter: null,
};

const slice = createSlice({
  name: 'candidateListKanban',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET BOARD
    getBoardSuccess(state, action) {
      state.isLoading = false;
      const board = action.payload;
      const cards = keyBy(board.cards, 'id');
      const columns = keyBy(board.columns, 'id');
      const { columnOrder } = board;

      state.board = {
        job: board.job,
        cards,
        columns,
        columnOrder,
      };
    },

    // GET CONTACTS LIST
    getContactListSuccess(state, action) {
      state.isLoading = false;
      const contactList = action.payload;

      state.contacts = contactList;
    },

    // Recruiters and Hiring Managers
    persistHiringManager(state, action) {
      const hiringManager = action.payload;
      state.hiringManager = hiringManager;
    },

    persistRecruiter(state, action) {
      const recruiter = action.payload;
      state.recruiter = recruiter;
    },

    // CREATE NEW COLUMN
    createColumnSuccess(state, action) {
      const newColumn = action.payload;
      state.isLoading = false;
      state.board.columns = {
        ...state.board.columns,
        [newColumn.id]: newColumn,
      };
      state.board.columnOrder.push(newColumn.id);
    },

    persistCard(state, action) {
      const columns = action.payload;
      state.board.columns = columns;
    },

    persistColumn(state, action) {
      state.board.columnOrder = action.payload;
    },

    addTask(state, action) {
      const { card, columnId } = action.payload;

      state.board.cards[card.id] = card;
      state.board.columns[columnId].cardIds.push(card.id);
    },

    deleteTask(state, action) {
      const { cardId, columnId } = action.payload;

      state.board.columns[columnId].cardIds = state.board.columns[columnId].cardIds.filter(
        (id) => id !== cardId
      );

      state.board.cards = omit(state.board.cards, [cardId]);
    },

    // UPDATE COLUMN
    updateColumnSuccess(state, action) {
      const column = action.payload;

      state.isLoading = false;
      state.board.columns[column.id] = column;
    },

    // DELETE COLUMN
    deleteColumnSuccess(state, action) {
      const { columnId } = action.payload;
      const deletedColumn = state.board.columns[columnId];

      state.isLoading = false;
      state.board.columns = omit(state.board.columns, [columnId]);
      state.board.cards = omit(state.board.cards, [...deletedColumn.cardIds]);
      state.board.columnOrder = state.board.columnOrder.filter((c) => c !== columnId);
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getBoard(jobPostId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const jobPostResult = await DataStore.query(JobPost, jobPostId);
      // console.log('jobPostResult', jobPostResult);
      const jobPostWithKanban = await jobPostResult.JobPostKanbanColumns.toArray();
      // console.log('jobPostWithKanban', jobPostWithKanban);

      const hiringManager = await DataStore.query(UserProfile, jobPostResult.hiringManagerId);
      const recruiter = await DataStore.query(UserProfile, jobPostResult.recruiterId);

      const candidateListResult = await DataStore.query(JobApplications, (ja) =>
        ja.jobPostId.eq(jobPostResult.id)
      );
      // console.log('candidateListResult', candidateListResult);

      const candidateList = await Promise.all(
        candidateListResult.map(async (candidateApplication) => {

          if(!candidateApplication.applicantId) {
            return {
              id: candidateApplication.id,
              name: `${candidateApplication.firstName} ${candidateApplication.lastName}`,
              skill: candidateApplication.skills,
              completed: false,
              comments: await candidateApplication.JobApplicationComments.toArray(),
              assignee: await candidateApplication.JobApplicantAssignees.toArray(),
              attachments: [],
              ratings: await candidateApplication.JobApplicationRatings.toArray(),
            };
          }

          const applicant = await DataStore.query(UserProfile, candidateApplication.applicantId);
          console.log('applicant', applicant)

          return {
            id: candidateApplication.id,
            name: `${applicant.firstName} ${applicant.lastName}`,
            completed: false,
            comments: await candidateApplication.JobApplicationComments.toArray(),
            assignee: await candidateApplication.JobApplicantAssignees.toArray(),
            attachments: [],
            ratings: await candidateApplication.JobApplicationRatings.toArray(),
            applicantProfileId: candidateApplication.applicantProfileId,
          };
        })
      );

      const jobPostColumns = jobPostWithKanban.map((column) => ({
        id: column.id,
        name: column.name,
        cardIds: candidateListResult
          .filter((application) => application.columnId === column.id)
          .map((application) => application.id),
      }));

      const candidateKanBanBoard = {
        job: jobPostResult,
        columnOrder: jobPostWithKanban.sort((a, b) => a.order - b.order).map((column) => column.id),
        columns: jobPostColumns.reduce((acc, item) => {
          acc[item.id] = item;
          return acc;
        }, {}),
        cards: candidateList.reduce((acc, item) => {
          acc[item.id] = item;
          return acc;
        }, {}),
      };

      // console.log('candidateKanBanBoard', candidateKanBanBoard);

      dispatch(slice.actions.persistHiringManager(hiringManager));
      dispatch(slice.actions.persistRecruiter(recruiter));
      dispatch(slice.actions.getBoardSuccess(candidateKanBanBoard));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getContactList(organizationId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await DataStore.query(UserProfile, (up) =>
        up.organizationId.eq(organizationId)
      );
      const refinedResponse = response.map((contact) => ({
        ...contact,
        name: `${contact.firstName} ${contact.lastName}`,
      }));
      dispatch(slice.actions.getContactListSuccess(refinedResponse));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createColumn(newColumn) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/kanban/columns/new', newColumn);
      dispatch(slice.actions.createColumnSuccess(response.data.column));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateColumnOrder(newColumnOrder) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await Promise.all(
        newColumnOrder.map(async (id, order) => {
          const targetColumn = await DataStore.query(CandidatesKanbanColumn, id);
          await DataStore.save(
            CandidatesKanbanColumn.copyOf(targetColumn, (updated) => {
              updated.order = order + 1;
            })
          );
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateColumn(columnId, column) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/kanban/columns/update', {
        columnId,
        column,
      });
      dispatch(slice.actions.updateColumnSuccess(response.data.column));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteColumn(columnId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/kanban/columns/delete', { columnId });
      dispatch(slice.actions.deleteColumnSuccess({ columnId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function persistColumn(newColumnOrder) {
  return (dispatch) => {
    dispatch(slice.actions.persistColumn(newColumnOrder));
  };
}

// ----------------------------------------------------------------------

export function persistCard(columns) {
  return (dispatch) => {
    dispatch(slice.actions.persistCard(columns));
  };
}

// ----------------------------------------------------------------------

export function addTask({ card, columnId }) {
  return (dispatch) => {
    dispatch(slice.actions.addTask({ card, columnId }));
  };
}

// ----------------------------------------------------------------------

export function deleteTask({ cardId, columnId }) {
  return (dispatch) => {
    dispatch(slice.actions.deleteTask({ cardId, columnId }));
  };
}
