// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  applicantRegister: path(ROOTS_AUTH, '/register/applicant'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  forceChangePassword: path(ROOTS_AUTH, '/create-new-password'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  jobSeeker: '/job-seeker',
  businesses: '/businesses',
  colleges: '/colleges',
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  jobSeekerPricing: '/jobseeker/pricing',
  employerPricing: '/employer/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  jobs: {
    listAll: '/jobs/list',
    listByOrg: (orgId) => `/jobs/list/${orgId}`,
    jobDetailView: (jobId) => `/jobs/view/${jobId}`,
    jobApply: (jobId) => `/jobs/apply/${jobId}`,
  },
  joinWaitList: '/join-wait-list',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  fileManager: path(ROOTS_DASHBOARD, '/files-manager'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  blank: path(ROOTS_DASHBOARD, '/blank'),
  general: {
    home: path(ROOTS_DASHBOARD, '/home'),
    myMenu: path(ROOTS_DASHBOARD, '/my-menu'),
    myBusinessOrders: path(ROOTS_DASHBOARD, '/my-business-orders'),
    adminOrderManagement: path(ROOTS_DASHBOARD, '/admin-order-management'),
    shopListings: path(ROOTS_DASHBOARD, '/shops'),
    myUserOrders: path(ROOTS_DASHBOARD, '/my-user-orders'),
    orderReceipt: (orderId) => path(ROOTS_DASHBOARD, `/order/${orderId}/receipt`),
    shopMenu: (id) => path(ROOTS_DASHBOARD, `/shops/${id}/menu`),
    jobs: path(ROOTS_DASHBOARD, '/jobs'),
    app: path(ROOTS_DASHBOARD, '/app'),
    applicant: path(ROOTS_DASHBOARD, '/applicant'),
    applicantProfile: (id) => path(ROOTS_DASHBOARD, `/applicantprofile/${id}`),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
    file: path(ROOTS_DASHBOARD, '/file'),
  },
  weekManagement: {
    root: path(ROOTS_DASHBOARD, '/week-management'),
    list: path(ROOTS_DASHBOARD, '/week-management/list'),
  },
  cart: {
    root: path(ROOTS_DASHBOARD, '/cart'),
    checkout: path(ROOTS_DASHBOARD, '/cart/checkout'),
  },
  businessManagement: {
    root: path(ROOTS_DASHBOARD, '/business-management'),
    list: path(ROOTS_DASHBOARD, '/business-management/list'),
    view: (id) => path(ROOTS_DASHBOARD, `/business-management/${id}/view`),
    new: path(ROOTS_DASHBOARD, '/business-management/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/business-management/${id}/edit`),
  },
  applications: {
    root: path(ROOTS_DASHBOARD, '/applications'),
    list: path(ROOTS_DASHBOARD, '/applications/list'),
  },
  profile: {
    root: path(ROOTS_DASHBOARD, '/profile'),
    list: path(ROOTS_DASHBOARD, '/profile/list'),
    view: (id) => path(ROOTS_DASHBOARD, `/profile/${id}/view`),
    new: path(ROOTS_DASHBOARD, '/profile/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/profile/${id}/edit`),
  },
  boomerangProfiles: {
    root: path(ROOTS_DASHBOARD, '/boomerang-profile'),
    list: path(ROOTS_DASHBOARD, `/boomerang-profile/list`),
    view: (profileId) => path(ROOTS_DASHBOARD, `/boomerang-profile/${profileId}/view`),
    manage: path(ROOTS_DASHBOARD, `/boomerang-profile/manage`),
  },
  workExperience: {
    root: path(ROOTS_DASHBOARD, '/work-experience'),
    list: path(ROOTS_DASHBOARD, '/work-experience/list'),
    view: (id) => path(ROOTS_DASHBOARD, `/work-experience/${id}`),
    new: path(ROOTS_DASHBOARD, '/work-experience/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/work-experience/${id}/edit`),
  },
  educationHistory: {
    root: path(ROOTS_DASHBOARD, '/education-history'),
    list: path(ROOTS_DASHBOARD, '/education-history/list'),
    view: (id) => path(ROOTS_DASHBOARD, `/education-history/${id}`),
    new: path(ROOTS_DASHBOARD, '/education-history/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/education-history/${id}/edit`),
  },
  certifications: {
    root: path(ROOTS_DASHBOARD, '/certification-history'),
    list: path(ROOTS_DASHBOARD, '/certification-history/list'),
    view: (id) => path(ROOTS_DASHBOARD, `/certification-history/${id}`),
    new: path(ROOTS_DASHBOARD, '/certification-history/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/certification-history/${id}/edit`),
  },
  videoIntroduction: {
    root: path(ROOTS_DASHBOARD, '/video-introduction'),
    list: path(ROOTS_DASHBOARD, '/video-introduction/list'),
    view: (id) => path(ROOTS_DASHBOARD, `/video-introduction/${id}`),
    new: path(ROOTS_DASHBOARD, '/video-introduction/new'),
    edit: (name) => path(ROOTS_DASHBOARD, `/video-introduction/${name}/edit`),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    messageCandidate: (candidateId) => path(ROOTS_DASHBOARD, `/chat/new/${candidateId}`),
    view: (conversationId) => path(ROOTS_DASHBOARD, `/chat/${conversationId}`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
  organization: {
    root: path(ROOTS_DASHBOARD, '/organization'),
    list: path(ROOTS_DASHBOARD, '/organization/list'),
    new: path(ROOTS_DASHBOARD, '/organization/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/organization/${id}/view`),
    edit: (id) => path(ROOTS_DASHBOARD, `/organization/${id}/edit`),
  },
  account: {
    root: path(ROOTS_DASHBOARD, '/account'),
    organization: path(ROOTS_DASHBOARD, '/account/organization/update'),
    settings: path(ROOTS_DASHBOARD, '/account/organization/settings'),
    subscription: path(ROOTS_DASHBOARD, '/account/organization/subscription'),
    users: path(ROOTS_DASHBOARD, '/account/organization/users'),
    createUser: path(ROOTS_DASHBOARD, '/account/organization/users/create'),
    editUser: (id) => path(ROOTS_DASHBOARD, `/account/organization/users/${id}/edit`),
  },
  networking: {
    root: path(ROOTS_DASHBOARD, '/networking'),
    home: path(ROOTS_DASHBOARD, '/networking/home'),
    invite: path(ROOTS_DASHBOARD, '/networking/invite'),
  },
  jobPostings: {
    root: path(ROOTS_DASHBOARD, '/job-postings'),
    list: path(ROOTS_DASHBOARD, '/job-postings/list'),
    kanban: path(ROOTS_DASHBOARD, '/job-postings/kanban'),
    view: (id) => path(ROOTS_DASHBOARD, `/job-postings/${id}/view`),
    new: path(ROOTS_DASHBOARD, '/job-postings/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/job-postings/${id}/edit`),
    candidateList: (id) => path(ROOTS_DASHBOARD, `/job-postings/${id}/candidates`),
  },
  recruitement: {
    candidateSearch: path(ROOTS_DASHBOARD, '/recruitement/candidates/search'),
  },
  contentCreation: {
    root: path(ROOTS_DASHBOARD, '/content-creator-suite'),
  },
  resourceContents: {
    videoContent: path(ROOTS_DASHBOARD, '/resources/video-content'),
    contentCreators: path(ROOTS_DASHBOARD, '/resources/content-creators'),
    contentCreatorProfile: (profileId) =>
      path(ROOTS_DASHBOARD, `/resources/content-creator-profile/${profileId}`),
  },
};

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1';
