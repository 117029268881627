import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Grid, Link, Stack, Divider, Container, Typography, IconButton } from '@mui/material';
// routes
// import { PATH_PAGE } from '../../routes/paths';
// _mock
// import { _socials } from '../../_mock/arrays';
// components
import Logo from '../../components/logo';
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

const _socials = [
  {
    value: 'facebook',
    name: 'FaceBook',
    icon: 'eva:facebook-fill',
    color: '#1877F2',
    path: 'https://www.facebook.com/mealsourced',
  },
  {
    value: 'instagram',
    name: 'Instagram',
    icon: 'ant-design:instagram-filled',
    color: '#E02D69',
    path: 'https://www.instagram.com/mealsourced',
  },
  // {
  //   value: 'linkedin',
  //   name: 'Linkedin',
  //   icon: 'eva:linkedin-fill',
  //   color: '#007EBB',
  //   path: 'https://www.linkedin.com/company/takenhire',
  // },
  // {
  //   value: 'twitter',
  //   name: 'Twitter',
  //   icon: 'eva:twitter-fill',
  //   color: '#00AAEC',
  //   path: 'https://www.twitter.com/caitlyn.kerluke',
  // },
  // {
  //   value: 'youtube',
  //   name: 'YouTube',
  //   icon: 'logos:youtube-icon',
  //   color: '#00AAEC',
  //   path: 'https://www.youtube.com/@NeighborFeast',
  // },
];

const LINKS = [
  // {
  //   headline: 'Meal Sourced',
  //   children: [
  //     // { name: 'About Us', href: PATH_PAGE.about },
  //     // { name: 'For Businesses', href: PATH_PAGE.businesses },
  //     // { name: 'For Colleges', href: PATH_PAGE.colleges },
  //     { name: 'Contact Us', href: PATH_PAGE.contact },
  //   ],
  // },
  // {
  //   headline: 'Legal',
  //   children: [
  //     {
  //       name: 'Privacy Policy',
  //       href: 'https://app.termly.io/document/terms-of-use-for-ecommerce/946b0ae1-88f6-45dd-8ac0-8a8d878f1f3b',
  //       target: '_blank',
  //       direct: true,
  //     },
  //     {
  //       name: 'Terms and Condition',
  //       href: 'https://app.termly.io/document/terms-of-use-for-ecommerce/946b0ae1-88f6-45dd-8ac0-8a8d878f1f3b',
  //       target: '_blank',
  //       direct: true,
  //     },
  //     {
  //       name: 'Disclaimer',
  //       href: 'https://app.termly.io/document/disclaimer/e0158c7f-766e-4920-bc61-45e91be88236',
  //       target: '_blank',
  //       direct: true,
  //     },
  //   ],
  // },
  {
    headline: 'Contact',
    children: [
      {
        name: 'team@mealsourced.com',
        href: 'mailto:team@mealsourced.com',
        direct: true,
        target: '_top',
      },
      {
        name: '254 Chapman Rd, Ste 208 #15580, Newark, Delaware 19702',
        href: 'https://goo.gl/maps/p1svmec8GQmoj5Ai8',
        direct: true,
        target: '_blank',
      },
    ],
  },
];

// ----------------------------------------------------------------------

export default function Footer() {
  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Divider />

      <Container sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
          sx={{
            textAlign: {
              xs: 'center',
              md: 'left',
            },
          }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
          </Grid>

          <Grid item xs={8} md={3}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              MealSourced: Your weekly ticket to a world of flavors — handpicked, restaurant-quality meals, bulk-delivered to liberate your evenings. No prep, all pleasure.
            </Typography>

            <Stack
              spacing={1}
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{
                mt: 5,
                mb: { xs: 5, md: 0 },
              }}
            >
              {_socials.map((social) => (
                <IconButton
                  key={social.name}
                  component="a"
                  href={social.path}
                  target="_blank"
                  rel="noopener"
                >
                  <Iconify icon={social.icon} color={social.color} />
                </IconButton>
              ))}
            </Stack>
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <Stack
              spacing={5}
              justifyContent="space-between"
              direction={{ xs: 'column', md: 'row' }}
            >
              {LINKS.map((list) => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                >
                  <Typography component="div" variant="overline">
                    {list.headline}
                  </Typography>

                  {list.children.map((link) => (
                    <Link
                      target={link?.target}
                      to={!link.direct ? link.href : ''}
                      href={link.direct ? link.href : ''}
                      key={link.name}
                      component={link.direct ? 'a' : RouterLink}
                      color="inherit"
                      variant="body2"
                      sx={{ display: 'block' }}
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          variant="caption"
          component="div"
          sx={{
            mt: 5,
            pb: 5,
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          © {new Date().getFullYear()}. Meal Sourced LLC. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );

  return mainFooter;
}
