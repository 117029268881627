import PropTypes from 'prop-types';
//
import ReactPlayer from 'react-player';

// ----------------------------------------------------------------------

SingleVideoPreview.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default function SingleVideoPreview({ file }) {
  if (!file) {
    return null;
  }

  const imgUrl = typeof file === 'string' ? file : file.preview;

  return (
    <ReactPlayer
      url={imgUrl}
      width="100%"
      height="auto"
      controls
      style={{
        top: 8,
        left: 8,
        zIndex: 8,
        borderRadius: 1,
        position: 'absolute',
        width: 'calc(100% - 16px)',
        height: 'calc(100% - 16px)',
      }}
    />
  );
}
