const Roles = {
    SUPER_ADMIN: {
        name: 'SuperAdmin',
        precedence: 1,
    },
    ADMIN: {
        name: 'Admin',
        precedence: 2,
    },
    SUPPLIER: {
        name: 'Supplier',
        precedence: 3,
    },
    CONSUMER: {
        name: 'Consumer',
        precedence: 4,
    },
}

export default Roles