import PropTypes from 'prop-types';
import { useRef } from 'react';
// router
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Button, AppBar, Toolbar, Container } from '@mui/material';
// hooks
import { useAuthContext } from '../../auth/useAuthContext';
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
// utils
import { bgBlur } from '../../utils/cssStyles';
// config
import { HEADER, PATH_AFTER_LOGIN } from '../../config-global';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
// import Logo from '../../components/logo';
//
import navConfig from './nav/config-navigation';
import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';

// ----------------------------------------------------------------------

export default function Header() {
  const carouselRef = useRef(null);

  const theme = useTheme();

  const isDesktop = useResponsive('up', 'md');

  const { isAuthenticated } = useAuthContext();

  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

  return (
    <AppBar ref={carouselRef} color="default" sx={{ boxShadow: 0 }}>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_MAIN_DESKTOP,
          },
          // transition: theme.transitions.create(['height', 'background-color'], {
          //   easing: theme.transitions.easing.easeInOut,
          //   duration: theme.transitions.duration.shorter,
          // }),
          ...(isOffset && {
            ...bgBlur({ color: theme.palette.background.default }),
            height: {
              md: HEADER.H_MAIN_DESKTOP - 16,
            },
          }),
        }}
      >
        <Container sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
          {/* <Typography
            variant="h3"
            component={RouterLink}
            to="/"
            sx={{
              textDecoration: 'none',
              color: 'primary.main',
            }}
          >
            Meal Sourced
          </Typography> */}
          {/* <Logo /> */}

          <RouterLink to="/" style={{ textDecoration: 'none' }}>
            <img
              src="/assets/home/meal-sourced-transparent-home-header.svg" // Replace with the path to your image
              alt="Meal Sourced"
              style={{
                height: '120px', // Adjust height as needed
                width: 'auto', // Adjust width as needed
                // Add other styles if necessary
              }}
            />
          </RouterLink>

          {/* <Link
            href={PATH_DOCS.changelog}
            target="_blank"
            rel="noopener"
            underline="none"
            sx={{ ml: 1 }}
          >
            <Label color="info"> v4.2.0 </Label>
          </Link> */}

          <Box sx={{ flexGrow: 1 }} />

          {isDesktop && <NavDesktop isOffset={isOffset} data={navConfig} />}

          {isDesktop && isAuthenticated && (
            <Button variant="contained" href={PATH_AFTER_LOGIN}>
              My Dashboard
            </Button>
          )}

          {/* {isDesktop && !isAuthenticated && (
            <Button variant="contained" href={PATH_AUTH.applicantRegister}>
              Sign Up
            </Button>
          )} */}

          {isDesktop && !isAuthenticated && (
            <Button variant="contained" href={PATH_AUTH.register}>
              Sign Up
            </Button>
          )}

          {!isDesktop && <NavMobile isOffset={isOffset} data={navConfig} />}
        </Container>
      </Toolbar>

      {isOffset && <Shadow />}
    </AppBar>
  );
}

// ----------------------------------------------------------------------

Shadow.propTypes = {
  sx: PropTypes.object,
};

function Shadow({ sx, ...other }) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 48px)`,
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}
