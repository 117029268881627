import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
import { API, Amplify, Auth, graphqlOperation } from 'aws-amplify';
import {
  CognitoUser,
  CognitoUserPool,
  CognitoUserAttribute,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';
// utils
import axios from '../utils/axios';
import { COGNITO_API } from '../config-global';
// constants
import { USER_DESIGNATION } from '../constants/AppConstants';
// routes
import { PATH_AUTH } from '../routes/paths';
// graphql
import { cartsByUserId } from '../graphql/queries';
// config
import awsconfig from '../aws-exports';

Amplify.configure(awsconfig);

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'AUTH') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === 'UPDATE_USER_CART') {
    // Ensure the user object exists before trying to update it
    if (state.user) {
      return {
        ...state,
        user: {
          ...state.user,
          cartId: action.payload.cartId, // Update the cartId here
        },
      };
    } 
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

const userPool = new CognitoUserPool({
  UserPoolId: awsconfig.aws_user_pools_id || COGNITO_API.userPoolId || '',
  ClientId: awsconfig.aws_user_pools_web_client_id || COGNITO_API.clientId || '',
});

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getUserAttributes = useCallback(
    (currentUser) =>
      new Promise((resolve, reject) => {
        currentUser.getUserAttributes((error, attributes) => {
          if (error) {
            reject(error);
            console.error(error);
          } else {
            const results = {};

            attributes?.forEach((attribute) => {
              results[attribute.Name] = attribute.Value;
            });

            resolve(results);
          }
        });
      }),
    []
  );

  const getSession = useCallback(
    () =>
      new Promise((resolve, reject) => {
        const cognitoUser = userPool.getCurrentUser();

        if (cognitoUser) {
          cognitoUser.getSession(async (error, session) => {
            if (error) {
              reject(error);
              console.error(error);
            }
            // console.log('cognitoUser', cognitoUser)
            const attributes = await getUserAttributes(cognitoUser);
            // console.log('attributes', attributes)
            attributes.organizationId = attributes['custom:organization_id'];
            attributes.organizationName = attributes['custom:organization_name'];

            const token = session?.getIdToken().getJwtToken();

            const groups = session.accessToken.payload['cognito:groups'];

            // use the token or Bearer depend on the wait BE handle, by default amplify API only need to token.
            axios.defaults.headers.common.Authorization = token;
            const accountSettings = {}
            // console.log('accountSettings', accountSettings)

            // get active cart for current user
            const cartListResponse = await API.graphql(graphqlOperation(cartsByUserId, {
              userId: cognitoUser.username
            }))
            const cartList = cartListResponse.data.cartsByUserId.items
            let cart = null;
            if(cartList.length > 0) {
              cart = cartList?.pop()
            }

            resolve({
              cognitoUser,
              session,
              headers: {
                Authorization: token,
              },
            });

            dispatch({
              type: 'AUTH',
              payload: {
                isAuthenticated: true,
                user: {
                  username: cognitoUser.username,
                  ...attributes,
                  tier: accountSettings?.tier,
                  tierInterval: accountSettings?.tierInterval,
                  displayName: attributes.name,
                  roles: groups,
                  contentCreatorId: accountSettings?.contentCreatorId,
                  cartId: cart?.id,
                },
              },
            });
          });
        } else {
          dispatch({
            type: 'AUTH',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      }),
    [getUserAttributes]
  );

  const initialize = useCallback(async () => {
    try {
      await getSession();
    } catch {
      dispatch({
        type: 'AUTH',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [getSession]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // We make sure to handle the user update here, but return the resolve value in order for our components to be
  // able to chain additional `.then()` logic. Additionally, we `.catch` the error and "enhance it" by providing
  // a message that our React components can use.

  // LOGIN
  const login = useCallback(
    (email, password) =>
      new Promise((resolve, reject) => {
        const userData = new CognitoUser({
          Username: email,
          Pool: userPool,
        });

        const authDetails = new AuthenticationDetails({
          Username: email,
          Password: password,
        });

        userData.authenticateUser(authDetails, {
          onSuccess: (result) => {
            getSession();
            resolve(result);
          },
          newPasswordRequired: (userAttributes, requiredAttributes) => {
            window.location.href = PATH_AUTH.forceChangePassword;
          },
          onFailure: (error) => {
            console.error('Login Failed', error);
            reject(error);
          },
        });
      }),
    [getSession]
  );

  // REGISTER Company
  const register = useCallback(
    (
      email,
      password,
      firstName,
      lastName,
      userDesignation,
      setUnverifiedUsername,
      setUnverifiedEmail,
    ) =>
      new Promise((resolve, reject) => {
        const newAttributes = [
          new CognitoUserAttribute({
            Name: 'email',
            Value: email,
          }),
          new CognitoUserAttribute({
            Name: 'name',
            Value: `${firstName} ${lastName}`,
          }),
          new CognitoUserAttribute({
            Name: 'given_name',
            Value: firstName,
          }),
          new CognitoUserAttribute({
            Name: 'family_name',
            Value: lastName,
          }),
          new CognitoUserAttribute({
            Name: 'custom:user_designation',
            Value: userDesignation,
          }),
        ];

        userPool.signUp(email, password, newAttributes, [], async (error, data) => {
          if (error) {
            reject(error);
            console.error(error);
            return;
          }

          setUnverifiedUsername(data.userSub);
          setUnverifiedEmail(email);
          resolve(undefined);
          window.location.href = PATH_AUTH.verify;
        });
      }),
    []
  );

  // REGISTER Applicant
  const registerApplicant = useCallback(
    (email, password, firstName, lastName, setUnverifiedUsername, setUnverifiedEmail) =>
      new Promise((resolve, reject) => {
        const newAttributes = [
          new CognitoUserAttribute({
            Name: 'email',
            Value: email,
          }),
          new CognitoUserAttribute({
            Name: 'name',
            Value: `${firstName} ${lastName}`,
          }),
          new CognitoUserAttribute({
            Name: 'given_name',
            Value: firstName,
          }),
          new CognitoUserAttribute({
            Name: 'family_name',
            Value: lastName,
          }),
          new CognitoUserAttribute({
            Name: 'custom:user_designation',
            Value: USER_DESIGNATION.APPLICANT,
          }),
        ];

        userPool.signUp(email, password, newAttributes, [], async (error, data) => {
          if (error) {
            reject(error);
            console.error(error);
            return;
          }

          setUnverifiedUsername(data.userSub);
          setUnverifiedEmail(email);
          resolve(undefined);
          window.location.href = PATH_AUTH.verify;
        });
      }),
    []
  );

  // User Verification
  const verifyUser = useCallback(
    (username, verificationCode, setUnverifiedUsername, setUnverifiedEmail) =>
      new Promise((resolve, reject) => {
        Auth.confirmSignUp(username, verificationCode).then((data) => {
          setUnverifiedUsername(undefined);
          setUnverifiedEmail(undefined);
        });
        // DataStore.clear().then(() => console.log('Clearing DataStore...'));
        resolve(undefined);
      }),
    []
  );

  // User Verification
  const createNewPassword = useCallback(
    (email, oldPassword, newPassword) =>
      new Promise((resolve, reject) => {
        Auth.signIn(email, oldPassword)
          .then(async (user) => {
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
              // Required attributes are OPTIONAL, but if they were configured
              // you need to get these in addition to new password from UI inputs
              // const { requiredAttributes } = user.challengeParam;
              // console.log('requiredAttributes', requiredAttributes)
              try {
                // const emailConfirmation = await Auth.verifyUserAttribute(user, 'email')
                // console.log('emailConfirmation', emailConfirmation)
                await Auth.completeNewPassword(user, newPassword);
                // await DataStore.clear();
                resolve(true);
              } catch (err) {
                reject(err);
              }
            }
          })
          .catch((err) => reject(err));
      }),
    []
  );

  // LOGOUT
  const logout = useCallback(() => {
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser) {
      cognitoUser.signOut();
      dispatch({
        type: 'LOGOUT',
      });
    }
  }, []);

  // Update the user's cart with the cart id
  const updateUserCart = useCallback(
    (cartId) =>
      new Promise((resolve, reject) => {
        console.log('Updating User Cart...')
        
        dispatch({
          type: 'UPDATE_USER_CART',
          payload: {
            cartId,
          },
        });
        
      }),
    []
  );


  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'cognito',
      login,
      register,
      registerApplicant,
      verifyUser,
      createNewPassword,
      logout,
      updateUserCart,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      login,
      register,
      registerApplicant,
      verifyUser,
      createNewPassword,
      logout,
      updateUserCart,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
