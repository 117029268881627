/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getContactInformation = /* GraphQL */ `
  query GetContactInformation($id: ID!) {
    getContactInformation(id: $id) {
      id
      name
      email
      phone
      createdAt
      updatedAt
      businessContactInformationId
      __typename
    }
  }
`;
export const listContactInformations = /* GraphQL */ `
  query ListContactInformations(
    $filter: ModelContactInformationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactInformations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        phone
        createdAt
        updatedAt
        businessContactInformationId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserProfile = /* GraphQL */ `
  query GetUserProfile($id: ID!) {
    getUserProfile(id: $id) {
      id
      firstName
      lastName
      status
      isEnabled
      rating
      businessProfiles {
        items {
          id
          businessId
          userProfileId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listUserProfiles = /* GraphQL */ `
  query ListUserProfiles(
    $filter: ModelUserProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        status
        isEnabled
        rating
        businessProfiles {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAccountSettings = /* GraphQL */ `
  query GetAccountSettings($id: ID!) {
    getAccountSettings(id: $id) {
      id
      email
      tier
      tierInterval
      stripeCustomerId
      subscriptionId
      contentCreatorId
      userType
      designation
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listAccountSettings = /* GraphQL */ `
  query ListAccountSettings(
    $filter: ModelAccountSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        tier
        tierInterval
        stripeCustomerId
        subscriptionId
        contentCreatorId
        userType
        designation
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      contentType
      body
      sender
      reciever
      attachments {
        id
        name
        path
        preview
        segmentKey
        size
        type
        dateCreated
        dateModified
        __typename
      }
      messageTimeStamp
      conversationId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        contentType
        body
        sender
        reciever
        attachments {
          id
          name
          path
          preview
          segmentKey
          size
          type
          dateCreated
          dateModified
          __typename
        }
        messageTimeStamp
        conversationId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConversation = /* GraphQL */ `
  query GetConversation($id: ID!) {
    getConversation(id: $id) {
      id
      participants {
        id
        name
        avatar
        email
        phone
        companyName
        __typename
      }
      type
      unreadCount
      lastMessage
      lastMessageId
      lastMessageType
      attachments {
        id
        name
        path
        preview
        segmentKey
        size
        type
        dateCreated
        dateModified
        __typename
      }
      userConversation {
        items {
          id
          userId
          otherUserId
          conversationId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listConversations = /* GraphQL */ `
  query ListConversations(
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConversations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        participants {
          id
          name
          avatar
          email
          phone
          companyName
          __typename
        }
        type
        unreadCount
        lastMessage
        lastMessageId
        lastMessageType
        attachments {
          id
          name
          path
          preview
          segmentKey
          size
          type
          dateCreated
          dateModified
          __typename
        }
        userConversation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserConversation = /* GraphQL */ `
  query GetUserConversation($id: ID!) {
    getUserConversation(id: $id) {
      id
      userId
      userInfo {
        id
        name
        avatar
        email
        phone
        companyName
        __typename
      }
      otherUserId
      otherUserInfo {
        id
        name
        avatar
        email
        phone
        companyName
        __typename
      }
      conversationId
      conversation {
        id
        participants {
          id
          name
          avatar
          email
          phone
          companyName
          __typename
        }
        type
        unreadCount
        lastMessage
        lastMessageId
        lastMessageType
        attachments {
          id
          name
          path
          preview
          segmentKey
          size
          type
          dateCreated
          dateModified
          __typename
        }
        userConversation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserConversations = /* GraphQL */ `
  query ListUserConversations(
    $filter: ModelUserConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserConversations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userInfo {
          id
          name
          avatar
          email
          phone
          companyName
          __typename
        }
        otherUserId
        otherUserInfo {
          id
          name
          avatar
          email
          phone
          companyName
          __typename
        }
        conversationId
        conversation {
          id
          type
          unreadCount
          lastMessage
          lastMessageId
          lastMessageType
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChatUser = /* GraphQL */ `
  query GetChatUser($id: ID!) {
    getChatUser(id: $id) {
      id
      firstName
      lastName
      email
      isOnline
      status
      avatar
      companyName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listChatUsers = /* GraphQL */ `
  query ListChatUsers(
    $filter: ModelChatUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        isOnline
        status
        avatar
        companyName
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChatContact = /* GraphQL */ `
  query GetChatContact($id: ID!) {
    getChatContact(id: $id) {
      id
      userId
      contactId
      name
      email
      avatar
      phone
      address
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listChatContacts = /* GraphQL */ `
  query ListChatContacts(
    $filter: ModelChatContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        contactId
        name
        email
        avatar
        phone
        address
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBusiness = /* GraphQL */ `
  query GetBusiness($id: ID!) {
    getBusiness(id: $id) {
      id
      name
      location {
        address
        unit
        city
        state
        zipCode
        country
        latitude
        longitude
        __typename
      }
      contactInformation {
        items {
          id
          name
          email
          phone
          createdAt
          updatedAt
          businessContactInformationId
          __typename
        }
        nextToken
        __typename
      }
      supplierMenus {
        items {
          id
          businessId
          menuName
          menuPicture
          rating
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      userProfiles {
        items {
          id
          businessId
          userProfileId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listBusinesses = /* GraphQL */ `
  query ListBusinesses(
    $filter: ModelBusinessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBusinesses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        location {
          address
          unit
          city
          state
          zipCode
          country
          latitude
          longitude
          __typename
        }
        contactInformation {
          nextToken
          __typename
        }
        supplierMenus {
          nextToken
          __typename
        }
        userProfiles {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSupplierMenu = /* GraphQL */ `
  query GetSupplierMenu($id: ID!) {
    getSupplierMenu(id: $id) {
      id
      businessId
      business {
        id
        name
        location {
          address
          unit
          city
          state
          zipCode
          country
          latitude
          longitude
          __typename
        }
        contactInformation {
          nextToken
          __typename
        }
        supplierMenus {
          nextToken
          __typename
        }
        userProfiles {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      menuName
      menuPicture
      rating
      menuItems {
        items {
          id
          menuId
          itemName
          description
          price
          servingSize
          itemPicture
          rating
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSupplierMenus = /* GraphQL */ `
  query ListSupplierMenus(
    $filter: ModelSupplierMenuFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSupplierMenus(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        businessId
        business {
          id
          name
          createdAt
          updatedAt
          owner
          __typename
        }
        menuName
        menuPicture
        rating
        menuItems {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSupplierMenuItem = /* GraphQL */ `
  query GetSupplierMenuItem($id: ID!) {
    getSupplierMenuItem(id: $id) {
      id
      menuId
      menu {
        id
        businessId
        business {
          id
          name
          createdAt
          updatedAt
          owner
          __typename
        }
        menuName
        menuPicture
        rating
        menuItems {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      itemName
      description
      price
      servingSize
      itemPicture
      rating
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSupplierMenuItems = /* GraphQL */ `
  query ListSupplierMenuItems(
    $filter: ModelSupplierMenuItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSupplierMenuItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        menuId
        menu {
          id
          businessId
          menuName
          menuPicture
          rating
          createdAt
          updatedAt
          __typename
        }
        itemName
        description
        price
        servingSize
        itemPicture
        rating
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBusinessUserProfile = /* GraphQL */ `
  query GetBusinessUserProfile($id: ID!) {
    getBusinessUserProfile(id: $id) {
      id
      businessId
      business {
        id
        name
        location {
          address
          unit
          city
          state
          zipCode
          country
          latitude
          longitude
          __typename
        }
        contactInformation {
          nextToken
          __typename
        }
        supplierMenus {
          nextToken
          __typename
        }
        userProfiles {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      userProfileId
      userProfile {
        id
        firstName
        lastName
        status
        isEnabled
        rating
        businessProfiles {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listBusinessUserProfiles = /* GraphQL */ `
  query ListBusinessUserProfiles(
    $filter: ModelBusinessUserProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBusinessUserProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessId
        business {
          id
          name
          createdAt
          updatedAt
          owner
          __typename
        }
        userProfileId
        userProfile {
          id
          firstName
          lastName
          status
          isEnabled
          rating
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCartItem = /* GraphQL */ `
  query GetCartItem($id: ID!) {
    getCartItem(id: $id) {
      id
      cartId
      menuItemId
      menuItem {
        id
        menuId
        menu {
          id
          businessId
          menuName
          menuPicture
          rating
          createdAt
          updatedAt
          __typename
        }
        itemName
        description
        price
        servingSize
        itemPicture
        rating
        createdAt
        updatedAt
        __typename
      }
      quantity
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCartItems = /* GraphQL */ `
  query ListCartItems(
    $filter: ModelCartItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCartItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cartId
        menuItemId
        menuItem {
          id
          menuId
          itemName
          description
          price
          servingSize
          itemPicture
          rating
          createdAt
          updatedAt
          __typename
        }
        quantity
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCart = /* GraphQL */ `
  query GetCart($id: ID!) {
    getCart(id: $id) {
      id
      userId
      businessId
      items {
        items {
          id
          cartId
          menuItemId
          quantity
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCarts = /* GraphQL */ `
  query ListCarts(
    $filter: ModelCartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCarts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        businessId
        items {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWeek = /* GraphQL */ `
  query GetWeek($id: ID!) {
    getWeek(id: $id) {
      id
      year
      week
      startDate
      startDay
      endDate
      endDay
      deliveryDate
      deliveryDay
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWeeks = /* GraphQL */ `
  query ListWeeks(
    $filter: ModelWeekFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWeeks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        year
        week
        startDate
        startDay
        endDate
        endDay
        deliveryDate
        deliveryDay
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrderItem = /* GraphQL */ `
  query GetOrderItem($id: ID!) {
    getOrderItem(id: $id) {
      id
      orderId
      menuItemId
      menuItem {
        id
        menuId
        menu {
          id
          businessId
          menuName
          menuPicture
          rating
          createdAt
          updatedAt
          __typename
        }
        itemName
        description
        price
        servingSize
        itemPicture
        rating
        createdAt
        updatedAt
        __typename
      }
      quantity
      price
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listOrderItems = /* GraphQL */ `
  query ListOrderItems(
    $filter: ModelOrderItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderId
        menuItemId
        menuItem {
          id
          menuId
          itemName
          description
          price
          servingSize
          itemPicture
          rating
          createdAt
          updatedAt
          __typename
        }
        quantity
        price
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      businessId
      weekId
      week {
        id
        year
        week
        startDate
        startDay
        endDate
        endDay
        deliveryDate
        deliveryDay
        createdAt
        updatedAt
        __typename
      }
      userId
      user {
        id
        firstName
        lastName
        status
        isEnabled
        rating
        businessProfiles {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      items {
        items {
          id
          orderId
          menuItemId
          quantity
          price
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      status
      total
      isPaid
      paymentId
      orderDate
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        businessId
        weekId
        week {
          id
          year
          week
          startDate
          startDay
          endDate
          endDay
          deliveryDate
          deliveryDay
          createdAt
          updatedAt
          __typename
        }
        userId
        user {
          id
          firstName
          lastName
          status
          isEnabled
          rating
          createdAt
          updatedAt
          owner
          __typename
        }
        items {
          nextToken
          __typename
        }
        status
        total
        isPaid
        paymentId
        orderDate
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSavedAddress = /* GraphQL */ `
  query GetSavedAddress($id: ID!) {
    getSavedAddress(id: $id) {
      id
      userId
      location {
        address
        unit
        city
        state
        zipCode
        country
        latitude
        longitude
        __typename
      }
      type
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listSavedAddresses = /* GraphQL */ `
  query ListSavedAddresses(
    $filter: ModelSavedAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSavedAddresses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        location {
          address
          unit
          city
          state
          zipCode
          country
          latitude
          longitude
          __typename
        }
        type
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBusinessOrder = /* GraphQL */ `
  query GetBusinessOrder($id: ID!) {
    getBusinessOrder(id: $id) {
      id
      businessId
      orderId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listBusinessOrders = /* GraphQL */ `
  query ListBusinessOrders(
    $filter: ModelBusinessOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBusinessOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        businessId
        orderId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const messagesByConversationId = /* GraphQL */ `
  query MessagesByConversationId(
    $conversationId: ID!
    $messageTimeStamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByConversationId(
      conversationId: $conversationId
      messageTimeStamp: $messageTimeStamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contentType
        body
        sender
        reciever
        attachments {
          id
          name
          path
          preview
          segmentKey
          size
          type
          dateCreated
          dateModified
          __typename
        }
        messageTimeStamp
        conversationId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userConversationsByUserId = /* GraphQL */ `
  query UserConversationsByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userConversationsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userInfo {
          id
          name
          avatar
          email
          phone
          companyName
          __typename
        }
        otherUserId
        otherUserInfo {
          id
          name
          avatar
          email
          phone
          companyName
          __typename
        }
        conversationId
        conversation {
          id
          type
          unreadCount
          lastMessage
          lastMessageId
          lastMessageType
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const conversationByUserIdOtherUserId = /* GraphQL */ `
  query ConversationByUserIdOtherUserId(
    $userId: ID!
    $otherUserId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conversationByUserIdOtherUserId(
      userId: $userId
      otherUserId: $otherUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userInfo {
          id
          name
          avatar
          email
          phone
          companyName
          __typename
        }
        otherUserId
        otherUserInfo {
          id
          name
          avatar
          email
          phone
          companyName
          __typename
        }
        conversationId
        conversation {
          id
          type
          unreadCount
          lastMessage
          lastMessageId
          lastMessageType
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userConversationsByConversationId = /* GraphQL */ `
  query UserConversationsByConversationId(
    $conversationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userConversationsByConversationId(
      conversationId: $conversationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userInfo {
          id
          name
          avatar
          email
          phone
          companyName
          __typename
        }
        otherUserId
        otherUserInfo {
          id
          name
          avatar
          email
          phone
          companyName
          __typename
        }
        conversationId
        conversation {
          id
          type
          unreadCount
          lastMessage
          lastMessageId
          lastMessageType
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const chatContactsByUserId = /* GraphQL */ `
  query ChatContactsByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelChatContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatContactsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        contactId
        name
        email
        avatar
        phone
        address
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const contactListByUserIdContactId = /* GraphQL */ `
  query ContactListByUserIdContactId(
    $userId: ID!
    $contactId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contactListByUserIdContactId(
      userId: $userId
      contactId: $contactId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        contactId
        name
        email
        avatar
        phone
        address
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const chatContactsByContactId = /* GraphQL */ `
  query ChatContactsByContactId(
    $contactId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelChatContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatContactsByContactId(
      contactId: $contactId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        contactId
        name
        email
        avatar
        phone
        address
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const chatContactsByName = /* GraphQL */ `
  query ChatContactsByName(
    $name: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelChatContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatContactsByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        contactId
        name
        email
        avatar
        phone
        address
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const supplierMenusByBusinessId = /* GraphQL */ `
  query SupplierMenusByBusinessId(
    $businessId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSupplierMenuFilterInput
    $limit: Int
    $nextToken: String
  ) {
    supplierMenusByBusinessId(
      businessId: $businessId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessId
        business {
          id
          name
          createdAt
          updatedAt
          owner
          __typename
        }
        menuName
        menuPicture
        rating
        menuItems {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const supplierMenuItemsByMenuId = /* GraphQL */ `
  query SupplierMenuItemsByMenuId(
    $menuId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSupplierMenuItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    supplierMenuItemsByMenuId(
      menuId: $menuId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        menuId
        menu {
          id
          businessId
          menuName
          menuPicture
          rating
          createdAt
          updatedAt
          __typename
        }
        itemName
        description
        price
        servingSize
        itemPicture
        rating
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const businessUserProfilesByBusinessId = /* GraphQL */ `
  query BusinessUserProfilesByBusinessId(
    $businessId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBusinessUserProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    businessUserProfilesByBusinessId(
      businessId: $businessId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessId
        business {
          id
          name
          createdAt
          updatedAt
          owner
          __typename
        }
        userProfileId
        userProfile {
          id
          firstName
          lastName
          status
          isEnabled
          rating
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const businessUserProfilesByUserProfileId = /* GraphQL */ `
  query BusinessUserProfilesByUserProfileId(
    $userProfileId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBusinessUserProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    businessUserProfilesByUserProfileId(
      userProfileId: $userProfileId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessId
        business {
          id
          name
          createdAt
          updatedAt
          owner
          __typename
        }
        userProfileId
        userProfile {
          id
          firstName
          lastName
          status
          isEnabled
          rating
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const cartItemsByCartId = /* GraphQL */ `
  query CartItemsByCartId(
    $cartId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCartItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cartItemsByCartId(
      cartId: $cartId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cartId
        menuItemId
        menuItem {
          id
          menuId
          itemName
          description
          price
          servingSize
          itemPicture
          rating
          createdAt
          updatedAt
          __typename
        }
        quantity
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const cartsByUserId = /* GraphQL */ `
  query CartsByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cartsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        businessId
        items {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const cartsByBusinessId = /* GraphQL */ `
  query CartsByBusinessId(
    $businessId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cartsByBusinessId(
      businessId: $businessId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        businessId
        items {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const weeksByYearId = /* GraphQL */ `
  query WeeksByYearId(
    $year: Int!
    $deliveryDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWeekFilterInput
    $limit: Int
    $nextToken: String
  ) {
    weeksByYearId(
      year: $year
      deliveryDate: $deliveryDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        year
        week
        startDate
        startDay
        endDate
        endDay
        deliveryDate
        deliveryDay
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const orderItemsByOrderId = /* GraphQL */ `
  query OrderItemsByOrderId(
    $orderId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrderItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderItemsByOrderId(
      orderId: $orderId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderId
        menuItemId
        menuItem {
          id
          menuId
          itemName
          description
          price
          servingSize
          itemPicture
          rating
          createdAt
          updatedAt
          __typename
        }
        quantity
        price
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ordersByBusinessIdAndWeekId = /* GraphQL */ `
  query OrdersByBusinessIdAndWeekId(
    $businessId: ID!
    $weekId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByBusinessIdAndWeekId(
      businessId: $businessId
      weekId: $weekId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessId
        weekId
        week {
          id
          year
          week
          startDate
          startDay
          endDate
          endDay
          deliveryDate
          deliveryDay
          createdAt
          updatedAt
          __typename
        }
        userId
        user {
          id
          firstName
          lastName
          status
          isEnabled
          rating
          createdAt
          updatedAt
          owner
          __typename
        }
        items {
          nextToken
          __typename
        }
        status
        total
        isPaid
        paymentId
        orderDate
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ordersByWeekId = /* GraphQL */ `
  query OrdersByWeekId(
    $weekId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByWeekId(
      weekId: $weekId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessId
        weekId
        week {
          id
          year
          week
          startDate
          startDay
          endDate
          endDay
          deliveryDate
          deliveryDay
          createdAt
          updatedAt
          __typename
        }
        userId
        user {
          id
          firstName
          lastName
          status
          isEnabled
          rating
          createdAt
          updatedAt
          owner
          __typename
        }
        items {
          nextToken
          __typename
        }
        status
        total
        isPaid
        paymentId
        orderDate
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ordersByUserId = /* GraphQL */ `
  query OrdersByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessId
        weekId
        week {
          id
          year
          week
          startDate
          startDay
          endDate
          endDay
          deliveryDate
          deliveryDay
          createdAt
          updatedAt
          __typename
        }
        userId
        user {
          id
          firstName
          lastName
          status
          isEnabled
          rating
          createdAt
          updatedAt
          owner
          __typename
        }
        items {
          nextToken
          __typename
        }
        status
        total
        isPaid
        paymentId
        orderDate
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const savedAddressesByUserId = /* GraphQL */ `
  query SavedAddressesByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSavedAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    savedAddressesByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        location {
          address
          unit
          city
          state
          zipCode
          country
          latitude
          longitude
          __typename
        }
        type
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const businessOrdersByBusinessId = /* GraphQL */ `
  query BusinessOrdersByBusinessId(
    $businessId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBusinessOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    businessOrdersByBusinessId(
      businessId: $businessId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessId
        orderId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
