// routes
import { PATH_DASHBOARD } from './routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || '';

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAP_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.home; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};

const getOrganizationPricingTableId = () => {
  switch (process.env.REACT_APP_AMPLIFY_ENV) {
    case 'prod':
      return 'TBD';
    case 'stage':
      return 'prctbl_1Nu0vxBk8kjkcqURtmfG5U0Z';
    case 'dev':
      return 'prctbl_1Nu0vxBk8kjkcqURtmfG5U0Z';
    default:
      return 'prctbl_1Nu0vxBk8kjkcqURtmfG5U0Z';
  }
};
export const ORGANIZATION_PRICING_TABLE_ID = getOrganizationPricingTableId();

const getStripePublicKey = () => {
  switch (process.env.REACT_APP_AMPLIFY_ENV) {
    case 'prod':
      return 'pk_live_51NrkczBk8kjkcqURLPU49PHxb1lplz7AIhKXQ1uhsRLZ6uKkDNcVVoqR4VyC1jT3BBUJeOd6z33FXpcIgH3cHDMG00LN984Syr';
    case 'stage':
      return 'pk_test_51NrkczBk8kjkcqURuNT67o0XsJVgIwEj0vBM8ezA0OATRCEJXTh2w64FQqmD64i7EuoWDsuuJcPsdkf8R7G5KskG006oim4RRk';
    case 'dev':
      return 'pk_test_51NrkczBk8kjkcqURuNT67o0XsJVgIwEj0vBM8ezA0OATRCEJXTh2w64FQqmD64i7EuoWDsuuJcPsdkf8R7G5KskG006oim4RRk';
    default:
      return 'pk_test_51NrkczBk8kjkcqURuNT67o0XsJVgIwEj0vBM8ezA0OATRCEJXTh2w64FQqmD64i7EuoWDsuuJcPsdkf8R7G5KskG006oim4RRk';
  }
};

export const STRIPE_PUBLIC_KEY = getStripePublicKey();

const getOrganizationPricingIndex = () => {
  switch (process.env.REACT_APP_AMPLIFY_ENV) {
    case 'prod':
      return {
        GateWay: {
          monthly: '',
          yearly: '',
        },
        StreamLiner: {
          monthly: '',
          yearly: '',
        },
        Explorer: {
          monthly: '',
          yearly: '',
        },
      };
    default:
      return {
        GateWay: {
          monthly: 'price_1NsxQSBk8kjkcqURS2VxIeQ7',
          yearly: 'price_1Nu0qXBk8kjkcqURxc1IRGdi',
        },
        StreamLiner: {
          monthly: 'price_1NsxNeBk8kjkcqURZcSvs3sV',
          yearly: 'price_1NsxNeBk8kjkcqURnQ2a92Yd',
        },
        Explorer: {
          monthly: 'price_1NsxBzBk8kjkcqURRfdjgW6b',
          yearly: 'price_1NsxBzBk8kjkcqURRfdjgW6b',
        },
      };
  }
};

export const ORGANIZATION_SUBSCRIPTION_PRICES = getOrganizationPricingIndex()
