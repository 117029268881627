// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
// import Label from '../../../components/label';
// import Iconify from '../../../components/iconify';
import SvgColor from '../../../components/svg-color';
import Roles from '../../../constants/Roles';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  jobsearch: icon('ic_job_search'),
  candidatesearch: icon('ic_candidate_search'),
};

const navConfig = [
  {
    subheader: 'Dashboard',
    accessRoles: [
      Roles.SUPPLIER.name,
      Roles.CONSUMER.name,
      Roles.ADMIN.name,
      Roles.SUPER_ADMIN.name,
    ],
    items: [
      {
        title: 'home',
        path: PATH_DASHBOARD.general.home,
        icon: ICONS.dashboard,
        accessRoles: [
          Roles.SUPPLIER.name,
          Roles.CONSUMER.name,
          Roles.ADMIN.name,
          Roles.SUPER_ADMIN.name,
        ],
      },
      // {
      //   title: 'my menu',
      //   path: PATH_DASHBOARD.general.myMenu,
      //   icon: ICONS.menuItem,
      //   accessRoles: [Roles.SUPPLIER.name],
      // },
      {
        title: 'my orders',
        path: PATH_DASHBOARD.general.myBusinessOrders,
        icon: ICONS.menuItem,
        accessRoles: [Roles.SUPPLIER.name],
      },
      {
        title: 'shop listings',
        path: PATH_DASHBOARD.general.shopListings,
        icon: ICONS.menuItem,
        accessRoles: [Roles.CONSUMER.name],
      },
      {
        title: 'My Orders',
        path: PATH_DASHBOARD.general.myUserOrders,
        icon: ICONS.menuItem,
        accessRoles: [Roles.CONSUMER.name],
      },
      // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
      // { title: 'file', path: PATH_DASHBOARD.general.file, icon: ICONS.file },
    ],
  },

  {
    subheader: 'data management',
    accessRoles: [Roles.ADMIN.name, Roles.SUPER_ADMIN.name],
    items: [
      {
        title: 'businesses',
        path: PATH_DASHBOARD.general.myMenu,
        icon: ICONS.menuItem,
        accessRoles: [Roles.ADMIN.name, Roles.SUPER_ADMIN.name],
        children: [
          {
            title: 'list',
            path: PATH_DASHBOARD.businessManagement.list,
            accessRoles: [Roles.ADMIN.name, Roles.SUPER_ADMIN.name],
          },
          {
            title: 'create',
            path: PATH_DASHBOARD.businessManagement.new,
            accessRoles: [Roles.ADMIN.name, Roles.SUPER_ADMIN.name],
          },
        ],
      },
      {
        title: 'week management',
        path: PATH_DASHBOARD.weekManagement.list,
        icon: ICONS.analytics,
        accessRoles: [Roles.ADMIN.name, Roles.SUPER_ADMIN.name],
      },
      {
        title: 'weekly orders',
        path: PATH_DASHBOARD.general.adminOrderManagement,
        icon: ICONS.menuItem,
        accessRoles: [Roles.ADMIN.name, Roles.SUPER_ADMIN.name],
      },
      // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
      // { title: 'file', path: PATH_DASHBOARD.general.file, icon: ICONS.file },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   accessRoles: [Roles.SUPER_ADMIN.name, Roles.ADMIN.name],
  //   items: [
  //     // CLIENTS
  //     {
  //       title: 'organizations',
  //       path: PATH_DASHBOARD.organization.root,
  //       icon: ICONS.banking,
  //       accessRoles: [Roles.SUPER_ADMIN.name],
  //       children: [
  //         { title: 'list', path: PATH_DASHBOARD.organization.list, accessRoles: [Roles.SUPER_ADMIN.name], },
  //         { title: 'create', path: PATH_DASHBOARD.organization.new, accessRoles: [Roles.SUPER_ADMIN.name], },
  //       ],
  //     },
  //     // ACCOUNT
  //     {
  //       title: 'account',
  //       path: PATH_DASHBOARD.account.root,
  //       icon: ICONS.banking,
  //       accessRoles: [Roles.COMPANY_ADMIN.name],
  //       children: [
  //         { title: 'my organization', path: PATH_DASHBOARD.account.organization, accessRoles: [Roles.COMPANY_ADMIN.name], },
  //         { title: 'account settings', path: PATH_DASHBOARD.account.settings, accessRoles: [Roles.COMPANY_ADMIN.name], },
  //         { title: 'users', path: PATH_DASHBOARD.account.users, accessRoles: [Roles.COMPANY_ADMIN.name], },
  //       ],
  //     },
  // // USER
  // {
  //   title: 'user',
  //   path: PATH_DASHBOARD.user.root,
  //   icon: ICONS.user,
  //   accessRoles: [Roles.SUPER_ADMIN.name],
  //   children: [
  //     { title: 'profile', path: PATH_DASHBOARD.user.profile, accessRoles: [], },
  //     { title: 'cards', path: PATH_DASHBOARD.user.cards, accessRoles: [], },
  //     { title: 'list', path: PATH_DASHBOARD.user.list, accessRoles: [], },
  //     { title: 'create', path: PATH_DASHBOARD.user.new, accessRoles: [], },
  //     { title: 'edit', path: PATH_DASHBOARD.user.demoEdit, accessRoles: [], },
  //     { title: 'account', path: PATH_DASHBOARD.user.account, accessRoles: [], },
  //   ],
  // },

  // // E-COMMERCE
  // {
  //   title: 'ecommerce',
  //   path: PATH_DASHBOARD.eCommerce.root,
  //   icon: ICONS.cart,
  //   accessRoles: [],
  //   children: [
  //     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop, accessRoles: [], },
  //     { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView, accessRoles: [], },
  //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list, accessRoles: [], },
  //     { title: 'create', path: PATH_DASHBOARD.eCommerce.new, accessRoles: [], },
  //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.demoEdit, accessRoles: [], },
  //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout, accessRoles: [], },
  //   ],
  // },

  // // INVOICE
  // {
  //   title: 'invoice',
  //   path: PATH_DASHBOARD.invoice.root,
  //   icon: ICONS.invoice,
  //   accessRoles: [],
  //   children: [
  //     { title: 'list', path: PATH_DASHBOARD.invoice.list, accessRoles: [], },
  //     { title: 'details', path: PATH_DASHBOARD.invoice.demoView, accessRoles: [], },
  //     { title: 'create', path: PATH_DASHBOARD.invoice.new, accessRoles: [], },
  //     { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit, accessRoles: [], },
  //   ],
  // },
  //   ],
  // },

  // {
  //   subheader: 'resources',
  //   accessRoles: [Roles.JOB_SEEKER.name],
  //   items: [
  //     {
  //       title: 'content creators',
  //       path: PATH_DASHBOARD.resourceContents.contentCreators,
  //       iconify: 'healthicons:ui-user-profile',
  //       accessRoles: [Roles.CONTENT_CREATOR.name],
  //     },
  //     {
  //       title: 'video resources',
  //       path: PATH_DASHBOARD.resourceContents.videoContent,
  //       iconify: 'mdi:youtube-creator-studio',
  //       accessRoles: [Roles.JOB_SEEKER.name],
  //     },
  //   ],
  // },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'network',
  //   accessRoles: [Roles.JOB_SEEKER.name, Roles.HIRING_MANAGER.name, Roles.COMPANY_ADMIN.name],
  //   items: [
  //     {
  //       title: 'invite professionals',
  //       path: PATH_DASHBOARD.networking.invite,
  //       icon: ICONS.mail,
  //       accessRoles: [Roles.JOB_SEEKER.name],
  //       info: <Label color="success">5</Label>,
  //     },
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       accessRoles: [],
  //       info: <Label color="error">+32</Label>,
  //     },
  //     {
  //       title: 'chat',
  //       path: PATH_DASHBOARD.chat.root,
  //       icon: ICONS.chat,
  //       accessRoles: [Roles.JOB_SEEKER.name, Roles.HIRING_MANAGER.name, Roles.COMPANY_ADMIN.name],
  //     },
  //     {
  //       title: 'calendar',
  //       path: PATH_DASHBOARD.calendar,
  //       icon: ICONS.calendar,
  //       accessRoles: [],
  //     },
  //     {
  //       title: 'kanban',
  //       path: PATH_DASHBOARD.kanban,
  //       icon: ICONS.kanban,
  //       accessRoles: [],
  //     },
  //   ],
  // },
  // {
  //   subheader: 'content creators',
  //   accessRoles: [Roles.CONTENT_CREATOR.name],
  //   items: [
  //     {
  //       title: 'creator suite',
  //       path: PATH_DASHBOARD.contentCreation.root,
  //       iconify: 'typcn:video',
  //       accessRoles: [Roles.CONTENT_CREATOR.name],
  //     },
  //   ],
  // },
];

export default navConfig;
