import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Grid, Link, Typography } from '@mui/material';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import { CustomAvatar } from '../../../components/custom-avatar';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
  const { user } = useAuthContext();

  return (
    <Link component={RouterLink} to={PATH_DASHBOARD.user.account} underline="none" color="inherit">
      <StyledRoot>
        <CustomAvatar src={user?.photoURL} alt={user?.displayName} name={user?.displayName} />

        <Box sx={{ ml: 2, minWidth: 0 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.displayName}
          </Typography>

          {/* <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            {user?.roles?.join('\n')}
          </Typography> */}

          <Grid container spacing={1}>
            <Grid item md={8}>
              <Typography variant="caption" noWrap sx={{ color: 'text.secondary' }}>
                Subscription:
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography variant="caption" noWrap sx={{ color: 'text.primary' }}>
                {user?.tier}
              </Typography>
            </Grid>
            {/* <Grid item md={8}>
              <Typography variant="caption" noWrap sx={{ color: 'text.secondary' }}>
                Vehicle Report Credits:
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography variant="caption" noWrap sx={{ color: 'text.primary' }}>
                {user?.vehicleReportCredits >= 0 ? user?.vehicleReportCredits : <Iconify icon={'iconoir:infinite'} />}
              </Typography>
            </Grid>
            <Grid item md={8}>
              <Typography variant="caption" noWrap sx={{ color: 'text.secondary' }}>
                Extra Listing Credits:
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography variant="caption" noWrap sx={{ color: 'text.primary' }}>
                {user?.extraListingCredits >= 0 ? user?.extraListingCredits : <Iconify icon={'ion:infinite-sharp'} />}
              </Typography>
            </Grid> */}
          </Grid>
        </Box>
      </StyledRoot>
    </Link>
  );
}
