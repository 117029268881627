import { API, graphqlOperation } from 'aws-amplify';
import keyBy from 'lodash/keyBy';
import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
// graphql
import { createConversation, createMessage, createUserConversation } from '../../graphql/mutations';
import {
  contactListByUserIdContactId,
  conversationByUserIdOtherUserId,
  getConversation,
  messagesByConversationId,
  userConversationsByUserId,
} from '../../graphql/queries';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  contacts: { byId: {}, allIds: [] },
  conversations: { byId: {}, allIds: [] },
  activeConversationId: null,
  participants: [],
  recipients: [],
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CONTACT SSUCCESS
    getContactsSuccess(state, action) {
      const contacts = action.payload;

      state.contacts.byId = keyBy(contacts, 'id');
      state.contacts.allIds = Object.keys(state.contacts.byId);
    },

    // GET CONVERSATIONS
    getConversationsSuccess(state, action) {
      const conversations = action.payload;

      state.conversations.byId = keyBy(conversations, 'id');
      state.conversations.allIds = Object.keys(state.conversations.byId);
    },

    // GET CONVERSATION
    getConversationSuccess(state, action) {
      const conversation = action.payload;

      if (conversation) {
        state.conversations.byId[conversation.id] = conversation;
        state.activeConversationId = conversation.id;
        if (!state.conversations.allIds.includes(conversation.id)) {
          state.conversations.allIds.push(conversation.id);
        }
      } else {
        state.activeConversationId = null;
      }
    },

    addConversationSuccess(state, action) {
      const newConversation = action.payload;

      // Prepend the new conversation ID to the allIds array
      state.conversations.allIds = [newConversation.id, ...state.conversations.allIds];

      // Add the new conversation to the byId object
      state.conversations.byId[newConversation.id] = newConversation;
    },

    // ON SEND MESSAGE
    sendMessage(state, action) {
      // console.log('Created new message', state.conversations);
      const newMessage = action.payload;
      // console.log('Created new message', newMessage);
      const { conversationId } = newMessage;
      // When the conversation doesn't exist yet in local list, that's usually because a new conversation has been created for this user
      state.conversations.byId[conversationId].messages.push(newMessage);
    },

    markConversationAsReadSuccess(state, action) {
      const { conversationId } = action.payload;
      const conversation = state.conversations.byId[conversationId];
      if (conversation) {
        conversation.unreadCount = 0;
      }
    },

    // GET PARTICIPANTS
    getParticipantsSuccess(state, action) {
      const participants = action.payload;
      state.participants = participants;
    },

    // RESET ACTIVE CONVERSATION
    resetActiveConversation(state) {
      state.activeConversationId = null;
    },

    addRecipients(state, action) {
      const recipients = action.payload;
      state.recipients = recipients;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { addRecipients, sendMessage, resetActiveConversation } = slice.actions;

// ----------------------------------------------------------------------

export function getContacts(username) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get('/api/chat/contacts');
      // console.log('response', response.data)
      const listOfContactsResults = await API.graphql(
        graphqlOperation(contactListByUserIdContactId, {
          userId: username,
        })
      );
      const listOfContacts = listOfContactsResults.data.contactListByUserIdContactId.items;
      // console.log('listOfContactsResults', listOfContacts)
      // dispatch(slice.actions.getContactsSuccess(response.data.contacts));
      dispatch(slice.actions.getContactsSuccess(listOfContacts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getConversations(currentUserId, conversationKey) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get('/api/chat/conversations');
      // console.log('response.data.conversations', response.data.conversations)
      const conversationResults = await API.graphql(
        graphqlOperation(userConversationsByUserId, {
          userId: currentUserId,
        })
      );
      const userConversationsList = conversationResults.data.userConversationsByUserId.items;
      const conversationList = await Promise.all(
        userConversationsList.map(async (userConversation) => {
          // console.log('userConversation', userConversation)
          const myLimit = userConversation.conversation.id === conversationKey ? 50 : 1;
          const messagesResults = await API.graphql(
            graphqlOperation(messagesByConversationId, {
              conversationId: userConversation.conversationId,
              sortDirection: 'DESC',
              limit: myLimit,
            })
          );
          return {
            id: userConversation.conversationId,
            messages: messagesResults.data.messagesByConversationId.items.reverse(),
            participants: userConversation.conversation.participants,
            type: userConversation.conversation.type,
            unreadCount: userConversation.unreadCount,
          };
        })
      );
      dispatch(slice.actions.getConversationsSuccess(conversationList));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getConversationAction(conversationKey) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const getConversationResult = await API.graphql(
        graphqlOperation(getConversation, {
          id: conversationKey,
        })
      );
      const messagesResults = await API.graphql(
        graphqlOperation(messagesByConversationId, {
          conversationId: conversationKey,
          sortDirection: 'DESC',
        })
      );
      const conversationItem = {
        ...getConversationResult.data.getConversation,
        messages: messagesResults.data.messagesByConversationId.items,
      };
      // const response = await axios.get('/api/chat/conversation', {
      //   params: { conversationKey: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2' },
      // });
      // console.log('Get Conversation: response.data.conversation', response.data.conversation)
      dispatch(slice.actions.getConversationSuccess(conversationItem));
      dispatch(slice.actions.getParticipantsSuccess(conversationItem.participants));
    } catch (error) {
      console.log('Get Conversation: error', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function markConversationAsRead(conversationId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.get('/api/chat/conversation/mark-as-seen', {
        params: { conversationId },
      });
      dispatch(slice.actions.markConversationAsReadSuccess({ conversationId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getParticipants(conversationKey) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/chat/participants', {
        params: { conversationKey },
      });
      dispatch(slice.actions.getParticipantsSuccess(response.data.participants));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ON SEND FIRST MESSAGE
export function sendFirstMessageOfConversation(data, navigateOnSuccess) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('Starting to send first message of conversation');
      const state = getState();
      const { recipients } = state.chat;
      const currentRecipient = recipients[0];
      const { currentUser, message, messageId, contentType } = data;

      const existingConversationResponse = await API.graphql(
        graphqlOperation(conversationByUserIdOtherUserId, {
          userId: currentUser.username,
          otherUserId: {
            eq: currentRecipient.contactId,
          },
        })
      );

      const existingConversationList =
        existingConversationResponse.data.conversationByUserIdOtherUserId.items;

      if (existingConversationList.length > 0) {
        console.log('Conversation Exists...');
        const currentUserConversation = existingConversationList[0];

        await API.graphql(
          graphqlOperation(createMessage, {
            input: {
              id: messageId,
              contentType,
              body: message,
              sender: currentUserConversation.userId,
              reciever: currentUserConversation.otherUserId,
              messageTimeStamp: new Date().toISOString(),
              conversationId: currentUserConversation.conversation.id,
            },
          })
        );
        navigateOnSuccess(currentUserConversation.conversation.id);
      } else {
        console.log('Does not Exist');
        const chatParticipantSender = {
          id: currentUser.username,
          name: currentUser.name,
          avatar: '',
          email: currentUser.email,
          phone: '',
          companyName: '',
        };

        const chatParticipantRecipient = {
          id: currentRecipient.contactId,
          name: currentRecipient.name,
          avatar: currentRecipient.avatar,
          email: currentRecipient.email,
          phone: '',
          companyName: '',
        };

        const participants = [chatParticipantSender, chatParticipantRecipient];

        // Create conversation
        const conversationId = uuidv4();
        // const createdConversation =
        await API.graphql(
          graphqlOperation(createConversation, {
            input: {
              id: conversationId,
              participants,
              type: 'ONE_TO_ONE',
              unreadCount: 1,
              lastMessageId: messageId,
              lastMessage: message,
              lastMessageType: contentType,
            },
          })
        );

        // Create User Conversation
        const userConversationId1 = uuidv4();
        // const userConversation1 =
        await API.graphql(
          graphqlOperation(createUserConversation, {
            input: {
              id: userConversationId1,
              userId: chatParticipantSender.id,
              userInfo: chatParticipantSender,
              otherUserId: chatParticipantRecipient.id,
              otherUserInfo: chatParticipantRecipient,
              conversationId,
            },
          })
        );

        const userConversationId2 = uuidv4();
        // const userConversation2 =
        await API.graphql(
          graphqlOperation(createUserConversation, {
            input: {
              id: userConversationId2,
              userId: chatParticipantRecipient.id,
              userInfo: chatParticipantRecipient,
              otherUserId: chatParticipantSender.id,
              otherUserInfo: chatParticipantSender,
              conversationId,
            },
          })
        );

        // Create Message
        // const createdMessage =
        await API.graphql(
          graphqlOperation(createMessage, {
            input: {
              id: messageId,
              contentType,
              body: message,
              sender: chatParticipantSender.id,
              reciever: chatParticipantRecipient.id,
              messageTimeStamp: new Date().toISOString(),
              conversationId,
            },
          })
        );

        // Make this the active conversation
        navigateOnSuccess(conversationId);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ON SEND MESSAGE
export function sendMessageAction(data) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('Starting to send message to conversation', data);
      const { messageId, contentType, message, sender, reciever, conversationId } = data;
      console.log('Sending a message to existing conversation', data);

      // Update conversation

      // Create Message
      const createdMessage = await API.graphql(
        graphqlOperation(createMessage, {
          input: {
            id: messageId,
            contentType,
            body: message,
            sender,
            reciever,
            messageTimeStamp: new Date().toISOString(),
            conversationId,
          },
        })
      );
      // console.log('createdMessage', createdMessage);

      dispatch(slice.actions.sendMessage(createdMessage.data.createMessage));

      // Make this the active conversation
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// Subscriptions to load conversations as they come in
export function handleNewIncomingConversation(userConversation, currentUserName) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // console.log('handleNewIncomingConversation', userConversation);

      const { conversation } = userConversation;
      // console.log('conversation', conversation);

      await delay(1000);

      const messagesResults = await API.graphql(
        graphqlOperation(messagesByConversationId, {
          conversationId: conversation.id,
          sortDirection: 'DESC',
        })
      );

      // console.log('messagesResults', messagesResults);
      const lastMessage = messagesResults.data.messagesByConversationId.items[0];
      if (lastMessage.sender === currentUserName) {
        // console.log('lastMessage.sender', lastMessage.sender);
      } else {
        const conversationItem = {
          ...conversation,
          messages: messagesResults.data.messagesByConversationId.items,
        };
        // console.log('conversationItem', conversationItem);

        dispatch(slice.actions.addConversationSuccess(conversationItem));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
