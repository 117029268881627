/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createContactInformation = /* GraphQL */ `
  mutation CreateContactInformation(
    $input: CreateContactInformationInput!
    $condition: ModelContactInformationConditionInput
  ) {
    createContactInformation(input: $input, condition: $condition) {
      id
      name
      email
      phone
      createdAt
      updatedAt
      businessContactInformationId
      __typename
    }
  }
`;
export const updateContactInformation = /* GraphQL */ `
  mutation UpdateContactInformation(
    $input: UpdateContactInformationInput!
    $condition: ModelContactInformationConditionInput
  ) {
    updateContactInformation(input: $input, condition: $condition) {
      id
      name
      email
      phone
      createdAt
      updatedAt
      businessContactInformationId
      __typename
    }
  }
`;
export const deleteContactInformation = /* GraphQL */ `
  mutation DeleteContactInformation(
    $input: DeleteContactInformationInput!
    $condition: ModelContactInformationConditionInput
  ) {
    deleteContactInformation(input: $input, condition: $condition) {
      id
      name
      email
      phone
      createdAt
      updatedAt
      businessContactInformationId
      __typename
    }
  }
`;
export const createUserProfile = /* GraphQL */ `
  mutation CreateUserProfile(
    $input: CreateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    createUserProfile(input: $input, condition: $condition) {
      id
      firstName
      lastName
      status
      isEnabled
      rating
      businessProfiles {
        items {
          id
          businessId
          userProfileId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateUserProfile = /* GraphQL */ `
  mutation UpdateUserProfile(
    $input: UpdateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    updateUserProfile(input: $input, condition: $condition) {
      id
      firstName
      lastName
      status
      isEnabled
      rating
      businessProfiles {
        items {
          id
          businessId
          userProfileId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteUserProfile = /* GraphQL */ `
  mutation DeleteUserProfile(
    $input: DeleteUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    deleteUserProfile(input: $input, condition: $condition) {
      id
      firstName
      lastName
      status
      isEnabled
      rating
      businessProfiles {
        items {
          id
          businessId
          userProfileId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createAccountSettings = /* GraphQL */ `
  mutation CreateAccountSettings(
    $input: CreateAccountSettingsInput!
    $condition: ModelAccountSettingsConditionInput
  ) {
    createAccountSettings(input: $input, condition: $condition) {
      id
      email
      tier
      tierInterval
      stripeCustomerId
      subscriptionId
      contentCreatorId
      userType
      designation
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateAccountSettings = /* GraphQL */ `
  mutation UpdateAccountSettings(
    $input: UpdateAccountSettingsInput!
    $condition: ModelAccountSettingsConditionInput
  ) {
    updateAccountSettings(input: $input, condition: $condition) {
      id
      email
      tier
      tierInterval
      stripeCustomerId
      subscriptionId
      contentCreatorId
      userType
      designation
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteAccountSettings = /* GraphQL */ `
  mutation DeleteAccountSettings(
    $input: DeleteAccountSettingsInput!
    $condition: ModelAccountSettingsConditionInput
  ) {
    deleteAccountSettings(input: $input, condition: $condition) {
      id
      email
      tier
      tierInterval
      stripeCustomerId
      subscriptionId
      contentCreatorId
      userType
      designation
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      contentType
      body
      sender
      reciever
      attachments {
        id
        name
        path
        preview
        segmentKey
        size
        type
        dateCreated
        dateModified
        __typename
      }
      messageTimeStamp
      conversationId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      contentType
      body
      sender
      reciever
      attachments {
        id
        name
        path
        preview
        segmentKey
        size
        type
        dateCreated
        dateModified
        __typename
      }
      messageTimeStamp
      conversationId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      contentType
      body
      sender
      reciever
      attachments {
        id
        name
        path
        preview
        segmentKey
        size
        type
        dateCreated
        dateModified
        __typename
      }
      messageTimeStamp
      conversationId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createConversation = /* GraphQL */ `
  mutation CreateConversation(
    $input: CreateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    createConversation(input: $input, condition: $condition) {
      id
      participants {
        id
        name
        avatar
        email
        phone
        companyName
        __typename
      }
      type
      unreadCount
      lastMessage
      lastMessageId
      lastMessageType
      attachments {
        id
        name
        path
        preview
        segmentKey
        size
        type
        dateCreated
        dateModified
        __typename
      }
      userConversation {
        items {
          id
          userId
          otherUserId
          conversationId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateConversation = /* GraphQL */ `
  mutation UpdateConversation(
    $input: UpdateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    updateConversation(input: $input, condition: $condition) {
      id
      participants {
        id
        name
        avatar
        email
        phone
        companyName
        __typename
      }
      type
      unreadCount
      lastMessage
      lastMessageId
      lastMessageType
      attachments {
        id
        name
        path
        preview
        segmentKey
        size
        type
        dateCreated
        dateModified
        __typename
      }
      userConversation {
        items {
          id
          userId
          otherUserId
          conversationId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteConversation = /* GraphQL */ `
  mutation DeleteConversation(
    $input: DeleteConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    deleteConversation(input: $input, condition: $condition) {
      id
      participants {
        id
        name
        avatar
        email
        phone
        companyName
        __typename
      }
      type
      unreadCount
      lastMessage
      lastMessageId
      lastMessageType
      attachments {
        id
        name
        path
        preview
        segmentKey
        size
        type
        dateCreated
        dateModified
        __typename
      }
      userConversation {
        items {
          id
          userId
          otherUserId
          conversationId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserConversation = /* GraphQL */ `
  mutation CreateUserConversation(
    $input: CreateUserConversationInput!
    $condition: ModelUserConversationConditionInput
  ) {
    createUserConversation(input: $input, condition: $condition) {
      id
      userId
      userInfo {
        id
        name
        avatar
        email
        phone
        companyName
        __typename
      }
      otherUserId
      otherUserInfo {
        id
        name
        avatar
        email
        phone
        companyName
        __typename
      }
      conversationId
      conversation {
        id
        participants {
          id
          name
          avatar
          email
          phone
          companyName
          __typename
        }
        type
        unreadCount
        lastMessage
        lastMessageId
        lastMessageType
        attachments {
          id
          name
          path
          preview
          segmentKey
          size
          type
          dateCreated
          dateModified
          __typename
        }
        userConversation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserConversation = /* GraphQL */ `
  mutation UpdateUserConversation(
    $input: UpdateUserConversationInput!
    $condition: ModelUserConversationConditionInput
  ) {
    updateUserConversation(input: $input, condition: $condition) {
      id
      userId
      userInfo {
        id
        name
        avatar
        email
        phone
        companyName
        __typename
      }
      otherUserId
      otherUserInfo {
        id
        name
        avatar
        email
        phone
        companyName
        __typename
      }
      conversationId
      conversation {
        id
        participants {
          id
          name
          avatar
          email
          phone
          companyName
          __typename
        }
        type
        unreadCount
        lastMessage
        lastMessageId
        lastMessageType
        attachments {
          id
          name
          path
          preview
          segmentKey
          size
          type
          dateCreated
          dateModified
          __typename
        }
        userConversation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserConversation = /* GraphQL */ `
  mutation DeleteUserConversation(
    $input: DeleteUserConversationInput!
    $condition: ModelUserConversationConditionInput
  ) {
    deleteUserConversation(input: $input, condition: $condition) {
      id
      userId
      userInfo {
        id
        name
        avatar
        email
        phone
        companyName
        __typename
      }
      otherUserId
      otherUserInfo {
        id
        name
        avatar
        email
        phone
        companyName
        __typename
      }
      conversationId
      conversation {
        id
        participants {
          id
          name
          avatar
          email
          phone
          companyName
          __typename
        }
        type
        unreadCount
        lastMessage
        lastMessageId
        lastMessageType
        attachments {
          id
          name
          path
          preview
          segmentKey
          size
          type
          dateCreated
          dateModified
          __typename
        }
        userConversation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createChatUser = /* GraphQL */ `
  mutation CreateChatUser(
    $input: CreateChatUserInput!
    $condition: ModelChatUserConditionInput
  ) {
    createChatUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      isOnline
      status
      avatar
      companyName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateChatUser = /* GraphQL */ `
  mutation UpdateChatUser(
    $input: UpdateChatUserInput!
    $condition: ModelChatUserConditionInput
  ) {
    updateChatUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      isOnline
      status
      avatar
      companyName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteChatUser = /* GraphQL */ `
  mutation DeleteChatUser(
    $input: DeleteChatUserInput!
    $condition: ModelChatUserConditionInput
  ) {
    deleteChatUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      isOnline
      status
      avatar
      companyName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createChatContact = /* GraphQL */ `
  mutation CreateChatContact(
    $input: CreateChatContactInput!
    $condition: ModelChatContactConditionInput
  ) {
    createChatContact(input: $input, condition: $condition) {
      id
      userId
      contactId
      name
      email
      avatar
      phone
      address
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateChatContact = /* GraphQL */ `
  mutation UpdateChatContact(
    $input: UpdateChatContactInput!
    $condition: ModelChatContactConditionInput
  ) {
    updateChatContact(input: $input, condition: $condition) {
      id
      userId
      contactId
      name
      email
      avatar
      phone
      address
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteChatContact = /* GraphQL */ `
  mutation DeleteChatContact(
    $input: DeleteChatContactInput!
    $condition: ModelChatContactConditionInput
  ) {
    deleteChatContact(input: $input, condition: $condition) {
      id
      userId
      contactId
      name
      email
      avatar
      phone
      address
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createBusiness = /* GraphQL */ `
  mutation CreateBusiness(
    $input: CreateBusinessInput!
    $condition: ModelBusinessConditionInput
  ) {
    createBusiness(input: $input, condition: $condition) {
      id
      name
      location {
        address
        unit
        city
        state
        zipCode
        country
        latitude
        longitude
        __typename
      }
      contactInformation {
        items {
          id
          name
          email
          phone
          createdAt
          updatedAt
          businessContactInformationId
          __typename
        }
        nextToken
        __typename
      }
      supplierMenus {
        items {
          id
          businessId
          menuName
          menuPicture
          rating
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      userProfiles {
        items {
          id
          businessId
          userProfileId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateBusiness = /* GraphQL */ `
  mutation UpdateBusiness(
    $input: UpdateBusinessInput!
    $condition: ModelBusinessConditionInput
  ) {
    updateBusiness(input: $input, condition: $condition) {
      id
      name
      location {
        address
        unit
        city
        state
        zipCode
        country
        latitude
        longitude
        __typename
      }
      contactInformation {
        items {
          id
          name
          email
          phone
          createdAt
          updatedAt
          businessContactInformationId
          __typename
        }
        nextToken
        __typename
      }
      supplierMenus {
        items {
          id
          businessId
          menuName
          menuPicture
          rating
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      userProfiles {
        items {
          id
          businessId
          userProfileId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteBusiness = /* GraphQL */ `
  mutation DeleteBusiness(
    $input: DeleteBusinessInput!
    $condition: ModelBusinessConditionInput
  ) {
    deleteBusiness(input: $input, condition: $condition) {
      id
      name
      location {
        address
        unit
        city
        state
        zipCode
        country
        latitude
        longitude
        __typename
      }
      contactInformation {
        items {
          id
          name
          email
          phone
          createdAt
          updatedAt
          businessContactInformationId
          __typename
        }
        nextToken
        __typename
      }
      supplierMenus {
        items {
          id
          businessId
          menuName
          menuPicture
          rating
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      userProfiles {
        items {
          id
          businessId
          userProfileId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createSupplierMenu = /* GraphQL */ `
  mutation CreateSupplierMenu(
    $input: CreateSupplierMenuInput!
    $condition: ModelSupplierMenuConditionInput
  ) {
    createSupplierMenu(input: $input, condition: $condition) {
      id
      businessId
      business {
        id
        name
        location {
          address
          unit
          city
          state
          zipCode
          country
          latitude
          longitude
          __typename
        }
        contactInformation {
          nextToken
          __typename
        }
        supplierMenus {
          nextToken
          __typename
        }
        userProfiles {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      menuName
      menuPicture
      rating
      menuItems {
        items {
          id
          menuId
          itemName
          description
          price
          servingSize
          itemPicture
          rating
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSupplierMenu = /* GraphQL */ `
  mutation UpdateSupplierMenu(
    $input: UpdateSupplierMenuInput!
    $condition: ModelSupplierMenuConditionInput
  ) {
    updateSupplierMenu(input: $input, condition: $condition) {
      id
      businessId
      business {
        id
        name
        location {
          address
          unit
          city
          state
          zipCode
          country
          latitude
          longitude
          __typename
        }
        contactInformation {
          nextToken
          __typename
        }
        supplierMenus {
          nextToken
          __typename
        }
        userProfiles {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      menuName
      menuPicture
      rating
      menuItems {
        items {
          id
          menuId
          itemName
          description
          price
          servingSize
          itemPicture
          rating
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSupplierMenu = /* GraphQL */ `
  mutation DeleteSupplierMenu(
    $input: DeleteSupplierMenuInput!
    $condition: ModelSupplierMenuConditionInput
  ) {
    deleteSupplierMenu(input: $input, condition: $condition) {
      id
      businessId
      business {
        id
        name
        location {
          address
          unit
          city
          state
          zipCode
          country
          latitude
          longitude
          __typename
        }
        contactInformation {
          nextToken
          __typename
        }
        supplierMenus {
          nextToken
          __typename
        }
        userProfiles {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      menuName
      menuPicture
      rating
      menuItems {
        items {
          id
          menuId
          itemName
          description
          price
          servingSize
          itemPicture
          rating
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSupplierMenuItem = /* GraphQL */ `
  mutation CreateSupplierMenuItem(
    $input: CreateSupplierMenuItemInput!
    $condition: ModelSupplierMenuItemConditionInput
  ) {
    createSupplierMenuItem(input: $input, condition: $condition) {
      id
      menuId
      menu {
        id
        businessId
        business {
          id
          name
          createdAt
          updatedAt
          owner
          __typename
        }
        menuName
        menuPicture
        rating
        menuItems {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      itemName
      description
      price
      servingSize
      itemPicture
      rating
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSupplierMenuItem = /* GraphQL */ `
  mutation UpdateSupplierMenuItem(
    $input: UpdateSupplierMenuItemInput!
    $condition: ModelSupplierMenuItemConditionInput
  ) {
    updateSupplierMenuItem(input: $input, condition: $condition) {
      id
      menuId
      menu {
        id
        businessId
        business {
          id
          name
          createdAt
          updatedAt
          owner
          __typename
        }
        menuName
        menuPicture
        rating
        menuItems {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      itemName
      description
      price
      servingSize
      itemPicture
      rating
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSupplierMenuItem = /* GraphQL */ `
  mutation DeleteSupplierMenuItem(
    $input: DeleteSupplierMenuItemInput!
    $condition: ModelSupplierMenuItemConditionInput
  ) {
    deleteSupplierMenuItem(input: $input, condition: $condition) {
      id
      menuId
      menu {
        id
        businessId
        business {
          id
          name
          createdAt
          updatedAt
          owner
          __typename
        }
        menuName
        menuPicture
        rating
        menuItems {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      itemName
      description
      price
      servingSize
      itemPicture
      rating
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createBusinessUserProfile = /* GraphQL */ `
  mutation CreateBusinessUserProfile(
    $input: CreateBusinessUserProfileInput!
    $condition: ModelBusinessUserProfileConditionInput
  ) {
    createBusinessUserProfile(input: $input, condition: $condition) {
      id
      businessId
      business {
        id
        name
        location {
          address
          unit
          city
          state
          zipCode
          country
          latitude
          longitude
          __typename
        }
        contactInformation {
          nextToken
          __typename
        }
        supplierMenus {
          nextToken
          __typename
        }
        userProfiles {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      userProfileId
      userProfile {
        id
        firstName
        lastName
        status
        isEnabled
        rating
        businessProfiles {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateBusinessUserProfile = /* GraphQL */ `
  mutation UpdateBusinessUserProfile(
    $input: UpdateBusinessUserProfileInput!
    $condition: ModelBusinessUserProfileConditionInput
  ) {
    updateBusinessUserProfile(input: $input, condition: $condition) {
      id
      businessId
      business {
        id
        name
        location {
          address
          unit
          city
          state
          zipCode
          country
          latitude
          longitude
          __typename
        }
        contactInformation {
          nextToken
          __typename
        }
        supplierMenus {
          nextToken
          __typename
        }
        userProfiles {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      userProfileId
      userProfile {
        id
        firstName
        lastName
        status
        isEnabled
        rating
        businessProfiles {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteBusinessUserProfile = /* GraphQL */ `
  mutation DeleteBusinessUserProfile(
    $input: DeleteBusinessUserProfileInput!
    $condition: ModelBusinessUserProfileConditionInput
  ) {
    deleteBusinessUserProfile(input: $input, condition: $condition) {
      id
      businessId
      business {
        id
        name
        location {
          address
          unit
          city
          state
          zipCode
          country
          latitude
          longitude
          __typename
        }
        contactInformation {
          nextToken
          __typename
        }
        supplierMenus {
          nextToken
          __typename
        }
        userProfiles {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      userProfileId
      userProfile {
        id
        firstName
        lastName
        status
        isEnabled
        rating
        businessProfiles {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCartItem = /* GraphQL */ `
  mutation CreateCartItem(
    $input: CreateCartItemInput!
    $condition: ModelCartItemConditionInput
  ) {
    createCartItem(input: $input, condition: $condition) {
      id
      cartId
      menuItemId
      menuItem {
        id
        menuId
        menu {
          id
          businessId
          menuName
          menuPicture
          rating
          createdAt
          updatedAt
          __typename
        }
        itemName
        description
        price
        servingSize
        itemPicture
        rating
        createdAt
        updatedAt
        __typename
      }
      quantity
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCartItem = /* GraphQL */ `
  mutation UpdateCartItem(
    $input: UpdateCartItemInput!
    $condition: ModelCartItemConditionInput
  ) {
    updateCartItem(input: $input, condition: $condition) {
      id
      cartId
      menuItemId
      menuItem {
        id
        menuId
        menu {
          id
          businessId
          menuName
          menuPicture
          rating
          createdAt
          updatedAt
          __typename
        }
        itemName
        description
        price
        servingSize
        itemPicture
        rating
        createdAt
        updatedAt
        __typename
      }
      quantity
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCartItem = /* GraphQL */ `
  mutation DeleteCartItem(
    $input: DeleteCartItemInput!
    $condition: ModelCartItemConditionInput
  ) {
    deleteCartItem(input: $input, condition: $condition) {
      id
      cartId
      menuItemId
      menuItem {
        id
        menuId
        menu {
          id
          businessId
          menuName
          menuPicture
          rating
          createdAt
          updatedAt
          __typename
        }
        itemName
        description
        price
        servingSize
        itemPicture
        rating
        createdAt
        updatedAt
        __typename
      }
      quantity
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCart = /* GraphQL */ `
  mutation CreateCart(
    $input: CreateCartInput!
    $condition: ModelCartConditionInput
  ) {
    createCart(input: $input, condition: $condition) {
      id
      userId
      businessId
      items {
        items {
          id
          cartId
          menuItemId
          quantity
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCart = /* GraphQL */ `
  mutation UpdateCart(
    $input: UpdateCartInput!
    $condition: ModelCartConditionInput
  ) {
    updateCart(input: $input, condition: $condition) {
      id
      userId
      businessId
      items {
        items {
          id
          cartId
          menuItemId
          quantity
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCart = /* GraphQL */ `
  mutation DeleteCart(
    $input: DeleteCartInput!
    $condition: ModelCartConditionInput
  ) {
    deleteCart(input: $input, condition: $condition) {
      id
      userId
      businessId
      items {
        items {
          id
          cartId
          menuItemId
          quantity
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createWeek = /* GraphQL */ `
  mutation CreateWeek(
    $input: CreateWeekInput!
    $condition: ModelWeekConditionInput
  ) {
    createWeek(input: $input, condition: $condition) {
      id
      year
      week
      startDate
      startDay
      endDate
      endDay
      deliveryDate
      deliveryDay
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWeek = /* GraphQL */ `
  mutation UpdateWeek(
    $input: UpdateWeekInput!
    $condition: ModelWeekConditionInput
  ) {
    updateWeek(input: $input, condition: $condition) {
      id
      year
      week
      startDate
      startDay
      endDate
      endDay
      deliveryDate
      deliveryDay
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteWeek = /* GraphQL */ `
  mutation DeleteWeek(
    $input: DeleteWeekInput!
    $condition: ModelWeekConditionInput
  ) {
    deleteWeek(input: $input, condition: $condition) {
      id
      year
      week
      startDate
      startDay
      endDate
      endDay
      deliveryDate
      deliveryDay
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrderItem = /* GraphQL */ `
  mutation CreateOrderItem(
    $input: CreateOrderItemInput!
    $condition: ModelOrderItemConditionInput
  ) {
    createOrderItem(input: $input, condition: $condition) {
      id
      orderId
      menuItemId
      menuItem {
        id
        menuId
        menu {
          id
          businessId
          menuName
          menuPicture
          rating
          createdAt
          updatedAt
          __typename
        }
        itemName
        description
        price
        servingSize
        itemPicture
        rating
        createdAt
        updatedAt
        __typename
      }
      quantity
      price
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateOrderItem = /* GraphQL */ `
  mutation UpdateOrderItem(
    $input: UpdateOrderItemInput!
    $condition: ModelOrderItemConditionInput
  ) {
    updateOrderItem(input: $input, condition: $condition) {
      id
      orderId
      menuItemId
      menuItem {
        id
        menuId
        menu {
          id
          businessId
          menuName
          menuPicture
          rating
          createdAt
          updatedAt
          __typename
        }
        itemName
        description
        price
        servingSize
        itemPicture
        rating
        createdAt
        updatedAt
        __typename
      }
      quantity
      price
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteOrderItem = /* GraphQL */ `
  mutation DeleteOrderItem(
    $input: DeleteOrderItemInput!
    $condition: ModelOrderItemConditionInput
  ) {
    deleteOrderItem(input: $input, condition: $condition) {
      id
      orderId
      menuItemId
      menuItem {
        id
        menuId
        menu {
          id
          businessId
          menuName
          menuPicture
          rating
          createdAt
          updatedAt
          __typename
        }
        itemName
        description
        price
        servingSize
        itemPicture
        rating
        createdAt
        updatedAt
        __typename
      }
      quantity
      price
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      businessId
      weekId
      week {
        id
        year
        week
        startDate
        startDay
        endDate
        endDay
        deliveryDate
        deliveryDay
        createdAt
        updatedAt
        __typename
      }
      userId
      user {
        id
        firstName
        lastName
        status
        isEnabled
        rating
        businessProfiles {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      items {
        items {
          id
          orderId
          menuItemId
          quantity
          price
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      status
      total
      isPaid
      paymentId
      orderDate
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      businessId
      weekId
      week {
        id
        year
        week
        startDate
        startDay
        endDate
        endDay
        deliveryDate
        deliveryDay
        createdAt
        updatedAt
        __typename
      }
      userId
      user {
        id
        firstName
        lastName
        status
        isEnabled
        rating
        businessProfiles {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      items {
        items {
          id
          orderId
          menuItemId
          quantity
          price
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      status
      total
      isPaid
      paymentId
      orderDate
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      businessId
      weekId
      week {
        id
        year
        week
        startDate
        startDay
        endDate
        endDay
        deliveryDate
        deliveryDay
        createdAt
        updatedAt
        __typename
      }
      userId
      user {
        id
        firstName
        lastName
        status
        isEnabled
        rating
        businessProfiles {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      items {
        items {
          id
          orderId
          menuItemId
          quantity
          price
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      status
      total
      isPaid
      paymentId
      orderDate
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createSavedAddress = /* GraphQL */ `
  mutation CreateSavedAddress(
    $input: CreateSavedAddressInput!
    $condition: ModelSavedAddressConditionInput
  ) {
    createSavedAddress(input: $input, condition: $condition) {
      id
      userId
      location {
        address
        unit
        city
        state
        zipCode
        country
        latitude
        longitude
        __typename
      }
      type
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateSavedAddress = /* GraphQL */ `
  mutation UpdateSavedAddress(
    $input: UpdateSavedAddressInput!
    $condition: ModelSavedAddressConditionInput
  ) {
    updateSavedAddress(input: $input, condition: $condition) {
      id
      userId
      location {
        address
        unit
        city
        state
        zipCode
        country
        latitude
        longitude
        __typename
      }
      type
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteSavedAddress = /* GraphQL */ `
  mutation DeleteSavedAddress(
    $input: DeleteSavedAddressInput!
    $condition: ModelSavedAddressConditionInput
  ) {
    deleteSavedAddress(input: $input, condition: $condition) {
      id
      userId
      location {
        address
        unit
        city
        state
        zipCode
        country
        latitude
        longitude
        __typename
      }
      type
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createBusinessOrder = /* GraphQL */ `
  mutation CreateBusinessOrder(
    $input: CreateBusinessOrderInput!
    $condition: ModelBusinessOrderConditionInput
  ) {
    createBusinessOrder(input: $input, condition: $condition) {
      id
      businessId
      orderId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateBusinessOrder = /* GraphQL */ `
  mutation UpdateBusinessOrder(
    $input: UpdateBusinessOrderInput!
    $condition: ModelBusinessOrderConditionInput
  ) {
    updateBusinessOrder(input: $input, condition: $condition) {
      id
      businessId
      orderId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteBusinessOrder = /* GraphQL */ `
  mutation DeleteBusinessOrder(
    $input: DeleteBusinessOrderInput!
    $condition: ModelBusinessOrderConditionInput
  ) {
    deleteBusinessOrder(input: $input, condition: $condition) {
      id
      businessId
      orderId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
